.product-link {
  font-size: 22px;
}

.product-grid-mode {
  .product-attributes {
    font-size: 0.875rem;
    margin: 5px 0;
    > span {
      margin: 0 5px 0 0;

      &:after {
        content: '|';
        padding: 0 0 0 5px
      }

      &:last-child {
        &:after {
          content: none;
        }
      }
    }
  }

  .grid-container {
    display: flex;

    @media (max-width: 992px) {
      display: block;
    }

    > .left-container {
      width: 20%;
      padding: 0 15px 0 0;

      @media (max-width: 992px) {
        width: 100%;

        .product-images {
          max-width: 400px;
          width: 100%;
          margin: auto;
        }
      }
    }

    > .right-container {
      width: 80%;

      @media (max-width: 992px) {
        width: 100%;
      }
    }

    .product-header {
      margin: 0 0 15px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media (max-width: 992px) {
        display: block;
      }

      .product-availability{
        margin: 5px 0;

        &.unavailable {
          color: red;
        }

        &.available {
          color: green;
        }

        &.first-tier {
          color: yellow;
        }

        &.second-tier {
          color: brown;
        }
      }

      .title-container {
        display: flex;
        align-items: center;
        margin: 0 0 5px 0;
        font-size: 22px;

        .title {
          @media (max-width: 992px) {
            margin: 0 0 5px 0;
          }
        }

        @media (max-width: 992px) {
          display: block;
        }

        .product-fabric {
          margin: 0 0 0 10px;

          @media (max-width: 992px) {
            margin: 0 0 5px 0;
          }

          &:before {
            padding: 0 10px 0 0;
            content: '|';

            @media (max-width: 992px) {
              content: none;
              padding: 0;
            }
          }
        }

      }
    }

    @media (max-width: 768px) {
      .multi-grid-wrapper{
        .multigrid-table {
          width: 100%;
          display: block;
          overflow: auto;
        }
      }
    }
  }
}

.product-price-container {
  font-size: 30px;
  font-weight: bold;
}

.simone-logo-container-product-page > img {
    width: 210px
}

.simone-logo-preorder-product{
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-bottom: 35px;
}
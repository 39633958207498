.quickview-component {
  position: fixed;
  top: 0;
  height: 100vh;
  background: #fff;
  box-shadow: -5px -10px 16px rgba(0, 0, 0, .15);
  max-width: 90vw;
  width: 100%;
  padding: 50px;
  z-index: 999;
  right: -110%;
  transition: right .3s ease-in-out;
  overflow: auto;

  .product-link {
    a {
      display: inline-block;
      color: #919191;
    }
  }

  .multigrid-table {
    .line-totals {
      padding: 0 15px;
    }
  }

  &.show {
    right: 0;
  }

  .delivery-week {
    text-align: right;
    margin: 10px 0;
    font-size: 1rem;

    .label {
      font-weight: bold;
      color: #383839;
      margin: 0 10px 0 0;
    }

    .value {
      font-weight: bold;
    }

  }

  .quickview-component-container {
    position: relative;

    .quickview-close {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 9;
    }

  }

  .product-quickview-wrapper {
    display: flex;
    align-items: center;

    .image-container {
      width: 10%;
      align-self: start;
      margin-top: 152px;
      margin-right: 20px;

      img {
        max-width: 100%;
        height: auto;
      }
    }

    .product-data-container {
      width: 90%;
    }
  }

  .product-header {
    line-height: 1;
    font-size: 1.375rem;
    letter-spacing: .02rem;
    color: #000;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .right-container {
      .product-price-container {
        display: flex;

        .label {
          margin-right: 10px;
        }

        .lds-roller {
          max-width: 10px;
        }

        .value {
          font-size: 1.4rem;
          color: #383839;
          font-weight: 700;

          &.old-price {
            text-decoration: line-through;
            color: #bfbfbf;
            margin-right: 5px;
            font-size: 1.2rem;
          }
        }
      }
    }

    .left-container {
      > div {
        margin: 0 0 15px 0;
      }

      .sku {
        .label {
          color: #919191;
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .plus-size-notice {
    font-size: 0.875rem;
  }

  .quickview-component {
    padding: 35px;

    .delivery-week {
      margin: 0;
    }

    .product-header {
      flex-wrap: wrap;
      font-size: 1rem;
      margin: 0 0 15px 0;

      .right-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        flex-wrap: wrap;

        .product-price-container {
          .value {
            font-size: 1.1rem;
          }
        }
      }
    }

    .product-quickview-wrapper {
      .multigrid-table {
        display: block;
        overflow-x: auto;
      }

      .product-data-container {
        flex: 1;
      }

      .image-container {
        min-width: 90px;
        flex: 1;
      }
    }
  }
}

@media (max-width: 650px) {

  .quickview-component {
    .product-quickview-wrapper {
      .product-data-container {
        margin-bottom: 200px;
      }
    }
  }

  .tablet-controls-component {
    //position: static;
    bottom: 0;
    width: 100%;
    left: 0;

    .table-controls-container {
      width: 100%;
      display: flex;
      justify-content: center;

      svg {
        max-width: 35px;
      }
    }
  }
}

@media (max-width: 560px) {

  .plus-size-notice {
    font-size: 0.875rem;
    text-align: center;
  }

  .quickview-component {
    padding: 25px;

    .multigrid-controls {
      flex-direction: column-reverse;

      .vamp-button {
        margin: 0 0 10px 0;
        width: 100%;
        max-width: 100%;
      }
    }

    .product-quickview-wrapper {
      flex-wrap: wrap;
    }

    .quickview-close {
      svg {
        max-width: 35px;
        width: 100%;
        height: auto;
      }
    }

    .product-quickview-wrapper {
      .image-container {
        width: 100%;
        min-width: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 0 25px 0;

        img {
          max-width: 110px;
          text-align: center;
          margin: auto;
        }
      }
    }
  }
}

.simone-logo-quick-buy-cart {
  width: 210px;
  margin-bottom: 5px;
}
.application-header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #383839;
  padding-bottom: 25px;
  margin-bottom: 25px;

  .sample-cart{
    left: 40px !important;
  }

  .new-product-label {
    left: 58px !important;
    background-color: green !important;
    width: 34px !important;
    font-size: 9px !important;
    border-radius: 16px !important;
    height: 17px !important;
  }

  .header-item {
    color: #383839;
    font-size: .813rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px 25px;
    text-align: center;
    border-right: 1px solid #d9d9d9;
    cursor: pointer;
    box-sizing: border-box;

    @media (max-width: 1400px) {
      padding: 15px 20px;

    }

    @media (max-width: 768px) {
      padding: 0 15px;
      border: 0;
    }

    &.cart-icon {
      position: relative;

      .counter {
        position: absolute;
        left: 0;
        top: 0;
        background: #000;
        color: #FFF;
        padding: 4px;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        font-size: 0.8rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

  }

  .left-header-container {

    display: flex;

    .secondary-banner {

      margin-left: 20px;

      img{
        width: 100%;
      }

      @media (max-width: 400px) {
        display: none;
      }
    }

    svg {
      max-width: 140px;
      height: auto;
      cursor: pointer;

      @media (max-width: 768px) {
        max-width: 100px;
      }

    }
  }

  .right-header-container {

    .right-header-inner {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .header-burger-menu {
      box-sizing: border-box;
      max-width: 200px;
      width: 100%;
      box-shadow: 0 0 10px #000;
      background: #FFF;
      padding: 15px;
      position: fixed;
      right: 0;
      top: 0;
      height: 100%;
      overflow-y: auto;
      transform: translateX(110%);
      transition: transform 0.3s ease-in-out;
      z-index: 99;
      color: #000;
      border: 0;

      .header-item {
        border: 0;
        margin: 0 0 15px 0;
        padding: 5px;
        width: 100%;
      }

      &.burger-menu-open {
        transform: translateX(0);
      }
    }

    svg {
      max-width: 25px;
      height: auto;
      width: 100%;
      margin-bottom: 5px;
    }

    .header-customer-container {
      a {
        text-decoration: none;
        color: #383839;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .header-customer-logout {
    padding: 0 30px;
    text-align: center;
  }

  @media (max-width: 768px) {

    .header-customer-logout {
      width: 100%;
      padding: 0;

      .vamp-button {
        margin: auto;
      }
    }
  }
}

.simone-perele-link-header {
  position: absolute;
  top: 132px;
  left: 0px;


  @media (max-width: 630px) {
    top: 125px;
  }

  @media (max-width: 400px) {
    top: 99px;
  }
}

.simone-logo-img-header {
  width: 210px;
  display: block;
  cursor: pointer;


  @media (max-width: 630px) {
    width: 115px;
  }

}


.customer-orders-panel {
  width: 100%;

  .dates-inputs {
    display: flex;

    > .form-group {
      margin-right: 15px;
    }
  }
}

.filter-orders-with-sessions{
  padding-bottom: 30px;
}

.filter-orders-with-sessions-label{
  font-size: 13px;
}
.admin-page {

  .admin-menu-navigator {
    border-bottom: 1px solid #383839;
    padding: 15px 0;

    a {
      background: #383839;
      color: #FFF;
      padding: 5px;
      margin: 0 5px 0 0 ;
      border: 1px solid #383839;
      text-transform: none;
      text-decoration: none;
      transition: background 0.3s ease-in-out, color 0.3s ease-in-out;

      &:hover {
        background: #FFF;
        color: #383839;
      }
    }
  }

  .user-password-input {
    .td-container {
      display: flex;
    }
  }

  .user-password-input {
    display: flex;
  }

  .basic-multi-select, .basic-select {
    width: 100%;
  }

  .form-element {
    button {
      background: #383839;
      color: #FFF;
      border: 0;
      padding: 12px 25px;
      max-width: 150px;
      width: 100%;
      cursor: pointer;
    }

    input[type='text'], input[type='email'] {
      width: 100%;
      max-width: 100%;
    }

  }

  .users-list {
    width: 100%;
    font-size: 0.875rem;

      @media (max-width: 768px) {
        overflow-x: auto;
        display: block;
      }

    th {
      background: #383839;
      color: #FFF;
    }

    td, th {
      padding: 5px;
    }

    input {
      padding: 6px;
    }

  }
}


.admin-section-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.4s ease-out;
}
@media (min-width: 1400px) {

  .menu-bg {
    display: none;
    background: #FFF;
    position: absolute;
    display: none;
    width: 100%;
    height: 450px;
    left: 0;
    z-index: 9;
    box-shadow: 0 20px 18px #38383970;
  }

  .menu-wrapper {
    &:hover {
      .menu-bg {
        display: none;
      }
    }
  }

  .archive-menu-navigator {
    .mobile-archive-menu-toggle, .mobile-archive-menu-close {
      display: none;
    }

    .submenu{
      a {
        margin: 0 0 10px 0;
        display: inline-block;
        padding: 2px;
        line-height: 1;
      }
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }

    .link-wrapper {
      a {
        transition: color 0.3s ease-in-out;
        color: #383839;
        font-size: 1.1rem;
        text-decoration: none;
      }

      svg {
        display: none;
      }
    }

    .menu-wrapper-container {
      display: flex;
      justify-content: center;
    }

    .menu-wrapper {
      display: inline-block;
      padding: 0 0 25px 0;

      > ul {
        display: flex;
        justify-content: center;
        z-index: 10;

        > li {
          padding: 0 10px;
          margin: 0 15px;
          transition: background 0.3s ease-in-out;
          
          &:hover {
            background: #383839;

            > .link-wrapper {
              a {
                color: #FFF;
              }
            }
          }

          > .submenu {
            //box-shadow: 0 18px 15px #38383973;
            padding: 25px 0;

            > li {
              > .link-wrapper {
                border-bottom: 1px solid #383839;
              }
            }

            .link-wrapper {

              a {
                transition: color 0.3s ease-in-out;
              }

              &:hover {
                a {
                  color: #3f4c7a !important;
                }
              }
            }

          }

          &.offers-menu {
            &:hover {
              > .submenu > li > .submenu {
                display: flex;
                justify-content: flex-start;

                > li {
                  padding: 0 30px 0 0;

                  > .link-wrapper {
                    a {
                      font-weight: bold;
                    }
                  }
                }
              }
            }
          }

          &:hover {
            > .submenu > li {
              padding: 15px 60px;

              > .link-wrapper {
                a {
                  font-weight: bold;
                }
              }

              .submenu {
                a {
                  font-size: 0.95rem;
                }
              }
            }

            .submenu {
              display: flex;

              > li {
                > .submenu {
                  display: block;
                }
              }
            }
          }

          > .submenu {
            position: absolute;
            left: 0;
            width: 100vw;
            z-index: 999;
            background-color: white;
          }
        }
      }

      .submenu {
        display: none;
        justify-content: center;
      }
    }
  }
}

@media(max-width: 1400px) {
  .archive-menu-navigator{

    .mobile-archive-menu-toggle, .mobile-archive-menu-close {
      display: block;
      text-align: center;
      padding: 0 0 25px 0;

      span {
        margin: auto;
        max-width: 100px;
      }
    }

    .mobile-archive-menu-close {
      text-align: center;
      padding: 5px;
      cursor: pointer;
      svg {
        max-width: 25px;
        width: 100%;
        height: auto;
      }
    }

    .link-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;

      svg {
        max-width: 25px;
        height: auto;
        width: 100%;
        padding: 5px;
        cursor: pointer;
      }
    }

    .menu-wrapper {
      background: #FFF;
      position: fixed;
      left: 0;
      top: 0;
      padding: 10px;
      z-index: 99;
      box-shadow: 0 0 10px #000;
      max-width: 200px;
      width: 100%;
      height: 100%;
      transform: translateX(-130%);
      transition: transform 0.3s ease-in-out;

      ul {
        list-style: none;
        padding: 0;
        margin: 0;

        .submenu {
          display: none;
          font-size: 0.9rem;
        }

        .show-submenu {

          > .link-wrapper {
            svg {
              transform: rotate(90deg);
            }
          }

          > .submenu {
            display: block;

            > li > .link-wrapper > a {
              padding-left: 25px;
            }
          }
        }

        .link-wrapper {
          border-bottom: 1px solid #d2d2d2;
        }

        a {
          display: block;
          padding: 15px 10px;
          color: #383839;
        }
      }
    }

    &.show-mobile-categories-menu {
      .menu-wrapper {
        transform: translateX(0);
      }
    }

  }
}

.simone-logo-img {
  width: 240px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-top: 36px;
}
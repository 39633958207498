@import url('https://fonts.googleapis.com/css?family=Noto+Sans:400,400i,700,700i&display=swap&subset=greek');

@import "Buttons";

.language-footer {
  height: 10px;
  position: fixed;
  bottom: 15px;
  right: 10px;
  span {
    cursor: pointer;
  }
}

.selected-language {
  color: #3f4c7a;
  text-decoration: underline;
}

.not-found-entry-page-message {
  text-align: center;

  .logo-container {
    margin: 35px 0;
  }

  h2 {
    font-size: 1.1rem;
  }

  .login-link {
    a {
      color: #FFF;
      background: #383839;
      margin: 25px 0;
      max-width: 150px;
      width: 100%;
      padding: 5px 10px;
      display: inline-block;
    }
  }
}

.prices {
  display: inline-block;

  .regular-price {
    text-decoration: line-through;
    color: #919191;
  }

  .price {
    color: #0a0a0a;
    font-weight: bold;
    padding: 0 0 0 5px;
  }
}

.fancybox-navigation  {
  .fancybox-button{
    height: 130px;
    width: 100px;

    @media (max-width: 560px) {
      height: 100px;
      width: 80px;
    }

  }
}

.carousel-item {
  display: block !important;
}

body, html {
  margin: 0;
  padding: 0;
}

body {
  font-size: 16px;
  font-family: 'Noto Sans', sans-serif;
}

div {
  box-sizing: border-box;
}

input, select, textarea {
  &:focus {
    outline: none;
  }
}

.application-container {
  max-width: 1400px;
  padding: 15px;
  margin: 0 auto 100px auto;
  position: static;
}

.loading-container {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination-component {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  list-style: none;
  margin: 25px 0;
  padding: 0;

  .pagination-number {
    padding: 12px;
    border: 1px solid #d9d9d9;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 50px;
    cursor: pointer;
    transition: background .3s ease-in-out,color .3s ease-in-out;

    &.active {
      background: #383839;
      color: #FFF;
    }
  }
}

.orders-table {
  width: 100%;
  border-spacing: 0;
  font-size: 0.875rem;

  @media (max-width: 768px)  {
    display: block;
    overflow-x: auto;
  }

  th {
    padding: 10px;
    background: #383839;
    color: #fff;
  }

  td {
    border-bottom: 1px solid #d9d9d9;
    padding: 5px;
    text-align: center;

    .action-area {
      cursor: pointer;
    }

    a {
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        margin-right: 5px;
      }
    }

    svg {
      max-width: 30px;
      height: auto;
      width: 100%;
      fill: #383839;
    }
  }
}

.form-submit-button {
  background: #383839;
  color: #fff;
  font-size: 18px;
  padding: 10px;
  cursor: pointer;
  border: 0;
}


.banner-temp-image {
  text-align: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 320px;
}

@import url(https://fonts.googleapis.com/css?family=Noto+Sans:400,400i,700,700i&display=swap&subset=greek);
.back-button {
  color: #919191;
  font-size: 1.2rem;
  background: none;
  border: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-decoration: none; }
  .back-button svg {
    margin-right: 5px;
    fill: #919191;
    max-width: 25px;
    width: 100%;
    height: auto; }

.action-button {
  color: #919191;
  font-size: 1rem;
  background: none;
  border: 0;
  cursor: pointer;
  display: flex;
  align-items: center; }
  .action-button svg {
    margin-right: 5px;
    fill: #919191;
    max-width: 25px;
    width: 100%;
    height: auto; }
  .action-button span {
    line-height: 1; }
  .action-button:focus {
    outline: none; }

.vamp-button {
  box-sizing: border-box;
  background: #fff;
  color: #383839;
  border: 1px solid #383839;
  max-width: 250px;
  text-align: center;
  padding: 8px 15px;
  cursor: pointer;
  transition: background 0.3s ease-in-out, color 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9rem; }
  .vamp-button.vamp-red {
    background: red;
    color: #FFF; }
  .vamp-button.vamp-green {
    background: green;
    color: #FFF; }
  .vamp-button svg {
    max-width: 20px;
    width: 100%;
    height: auto;
    margin-left: 20px; }
  .vamp-button:hover {
    background: #383839;
    color: #fff; }
  .vamp-button.vamp-button-blue {
    background: #383839;
    color: #FFF;
    border: 1px solid #383839; }
    .vamp-button.vamp-button-blue:hover {
      background: #FFF;
      color: #383839; }

.logout-button {
  border: 0;
  padding: 0;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center; }
  .logout-button svg {
    margin: 0 0 5px 0; }
  .logout-button:hover {
    background: #FFF;
    color: #383839; }

.search-result {
  font-size: .875rem;
  padding: 5px 0;
  margin: 0 0 5px 0;
  cursor: pointer;
  display: flex;
  align-items: center; }
  .search-result:hover .title {
    font-weight: bold; }
  .search-result img {
    max-width: 45px;
    height: auto;
    margin-right: 10px; }
  .search-result .code {
    font-weight: 700;
    color: #383839;
    padding: 0 10px; }
  .search-result .title {
    padding: 0 10px; }

.search-error {
  font-size: 14px;
  color: red;
  position: absolute; }

.search-results-list {
  position: absolute;
  top: 100%;
  left: 0;
  background: #fff;
  width: 100%;
  padding: 20px;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.16);
  z-index: 99;
  list-style: none;
  max-height: 350px;
  overflow: auto; }

.barcode-modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 9; }
  .barcode-modal .modal-container {
    position: relative;
    height: 100%;
    width: 100%; }
    .barcode-modal .modal-container .close-modal {
      width: 45px;
      height: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #383839;
      position: absolute;
      border-radius: 100%;
      right: 50px;
      top: 50px;
      z-index: 99;
      cursor: pointer; }
      .barcode-modal .modal-container .close-modal svg {
        fill: #FFF;
        width: 100%;
        max-width: 20px; }
        .barcode-modal .modal-container .close-modal svg path {
          fill: #FFF; }

.search-component-wrapper {
  display: flex;
  align-items: center; }
  .search-component-wrapper .barcode-scanner-button {
    margin-left: 15px; }
    .search-component-wrapper .barcode-scanner-button svg {
      max-width: 35px;
      width: 100%; }

.search-component {
  box-sizing: border-box;
  position: relative;
  width: 100%; }
  .search-component .lds-ellipsis {
    position: absolute;
    right: 80px;
    top: 3px; }
  .search-component .clear-icon {
    position: absolute;
    right: 0;
    top: 5px;
    z-index: 2;
    cursor: pointer; }
    .search-component .clear-icon svg {
      max-width: 20px; }
  .search-component .search-results {
    position: absolute;
    top: 100%;
    left: 0;
    background: #fff;
    width: 100%;
    padding: 20px;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.16);
    z-index: 99;
    list-style: none;
    box-sizing: border-box;
    margin: 0; }
    .search-component .search-results .code {
      padding-right: 15px;
      font-weight: bold;
      color: #383839; }
    .search-component .search-results .search-result {
      font-size: 14px;
      color: #999;
      transition: color 0.3s ease-in-out;
      cursor: pointer;
      padding: 5px 0;
      margin-bottom: 5px; }
      .search-component .search-results .search-result:hover {
        color: #383839; }

.search-input {
  position: relative;
  z-index: 0; }
  .search-input .search-icon {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2; }
  .search-input input {
    display: block;
    padding: 8px 40px 8px 45px;
    border: 0;
    border-bottom: 1px solid #383839;
    box-sizing: border-box;
    font-size: 1rem;
    width: 100%; }

.lds-roller {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px; }

.lds-roller div {
  -webkit-animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  -webkit-transform-origin: 32px 32px;
          transform-origin: 32px 32px; }

.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #383839;
  margin: -3px 0 0 -3px; }

.lds-roller div:nth-child(1) {
  -webkit-animation-delay: -0.036s;
          animation-delay: -0.036s; }

.lds-roller div:nth-child(1):after {
  top: 50px;
  left: 50px; }

.lds-roller div:nth-child(2) {
  -webkit-animation-delay: -0.072s;
          animation-delay: -0.072s; }

.lds-roller div:nth-child(2):after {
  top: 54px;
  left: 45px; }

.lds-roller div:nth-child(3) {
  -webkit-animation-delay: -0.108s;
          animation-delay: -0.108s; }

.lds-roller div:nth-child(3):after {
  top: 57px;
  left: 39px; }

.lds-roller div:nth-child(4) {
  -webkit-animation-delay: -0.144s;
          animation-delay: -0.144s; }

.lds-roller div:nth-child(4):after {
  top: 58px;
  left: 32px; }

.lds-roller div:nth-child(5) {
  -webkit-animation-delay: -0.18s;
          animation-delay: -0.18s; }

.lds-roller div:nth-child(5):after {
  top: 57px;
  left: 25px; }

.lds-roller div:nth-child(6) {
  -webkit-animation-delay: -0.216s;
          animation-delay: -0.216s; }

.lds-roller div:nth-child(6):after {
  top: 54px;
  left: 19px; }

.lds-roller div:nth-child(7) {
  -webkit-animation-delay: -0.252s;
          animation-delay: -0.252s; }

.lds-roller div:nth-child(7):after {
  top: 50px;
  left: 14px; }

.lds-roller div:nth-child(8) {
  -webkit-animation-delay: -0.288s;
          animation-delay: -0.288s; }

.lds-roller div:nth-child(8):after {
  top: 45px;
  left: 10px; }

@-webkit-keyframes lds-roller {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes lds-roller {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 20px; }

.lds-ellipsis div {
  position: absolute;
  top: 8px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #383839;
  -webkit-animation-timing-function: cubic-bezier(0, 1, 1, 0);
          animation-timing-function: cubic-bezier(0, 1, 1, 0); }

.lds-ellipsis div:nth-child(1) {
  left: 6px;
  -webkit-animation: lds-ellipsis1 0.6s infinite;
          animation: lds-ellipsis1 0.6s infinite; }

.lds-ellipsis div:nth-child(2) {
  left: 6px;
  -webkit-animation: lds-ellipsis2 0.6s infinite;
          animation: lds-ellipsis2 0.6s infinite; }

.lds-ellipsis div:nth-child(3) {
  left: 26px;
  -webkit-animation: lds-ellipsis2 0.6s infinite;
          animation: lds-ellipsis2 0.6s infinite; }

.lds-ellipsis div:nth-child(4) {
  left: 45px;
  -webkit-animation: lds-ellipsis3 0.6s infinite;
          animation: lds-ellipsis3 0.6s infinite; }

@-webkit-keyframes lds-ellipsis1 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes lds-ellipsis1 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@-webkit-keyframes lds-ellipsis3 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0); } }

@keyframes lds-ellipsis3 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0); } }

@-webkit-keyframes lds-ellipsis2 {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  100% {
    -webkit-transform: translate(19px, 0);
            transform: translate(19px, 0); } }

@keyframes lds-ellipsis2 {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  100% {
    -webkit-transform: translate(19px, 0);
            transform: translate(19px, 0); } }

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 30px;
  height: 30px; }

.lds-ellipsis div {
  position: absolute;
  top: 9px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #383839;
  -webkit-animation-timing-function: cubic-bezier(0, 1, 1, 0);
          animation-timing-function: cubic-bezier(0, 1, 1, 0); }

.lds-ellipsis div:nth-child(1) {
  left: 3px;
  -webkit-animation: lds-ellipsis1 0.6s infinite;
          animation: lds-ellipsis1 0.6s infinite; }

.lds-ellipsis div:nth-child(2) {
  left: 3px;
  -webkit-animation: lds-ellipsis2 0.6s infinite;
          animation: lds-ellipsis2 0.6s infinite; }

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  -webkit-animation: lds-ellipsis2 0.6s infinite;
          animation: lds-ellipsis2 0.6s infinite; }

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  -webkit-animation: lds-ellipsis3 0.6s infinite;
          animation: lds-ellipsis3 0.6s infinite; }

@keyframes lds-ellipsis1 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes lds-ellipsis3 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0); } }

@keyframes lds-ellipsis2 {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  100% {
    -webkit-transform: translate(24px, 0);
            transform: translate(24px, 0); } }

.cart-summary-widget {
  display: flex;
  align-items: center;
  font-size: 0.85rem; }
  .cart-summary-widget .cart-count-item {
    box-sizing: border-box; }
  .cart-summary-widget .cart-count {
    padding: 0 15px;
    box-sizing: border-box; }
  @media (max-width: 600px) {
    .cart-summary-widget {
      flex-wrap: wrap; }
      .cart-summary-widget .cart-count-item {
        width: 50%;
        text-align: left;
        padding: 0 15px;
        display: inline-block;
        margin: 0 0 5px 0; }
      .cart-summary-widget svg {
        display: none; } }

.cart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border: 1px solid #d9d9d9;
  position: relative;
  margin-bottom: 25px;
  box-sizing: border-box; }
  @media (max-width: 840px) {
    .cart-header {
      flex-wrap: wrap;
      flex-direction: column-reverse;
      align-items: flex-end; } }
  @media (max-width: 840px) {
    .cart-header .cart-actions-container {
      margin-bottom: 15px; } }
  .cart-header .search-container {
    max-width: 500px;
    width: 100%; }
    @media (max-width: 840px) {
      .cart-header .search-container {
        max-width: 100%; } }
  .cart-header svg {
    width: 25px;
    height: auto; }

.cart-header-sticky {
  min-height: 80px; }
  .cart-header-sticky .cart-count-item {
    display: none !important; }
  .cart-header-sticky .cart-header {
    position: fixed;
    z-index: 9;
    width: 100%;
    top: 0;
    background: #FFF;
    left: 0;
    padding: 10px; }

.cart-grid-header-row {
  background: transparent; }

.cart-table-header {
  min-width: 35px; }
  .cart-table-header svg {
    width: 35px;
    max-width: 35px;
    height: auto; }
  .cart-table-header.cart-header-product {
    width: 90px; }
  .cart-table-header span {
    border-left: 0;
    border-right: 0;
    font-size: 18px;
    font-weight: bold; }

.cart-header-color {
  width: 170px;
  text-align: center; }

.cart-header-price {
  width: 160px; }

.cart-header-size {
  padding-left: 30px; }

.cart-page-controls-container {
  display: flex;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  background: #383839;
  left: 0;
  width: 100%;
  padding: 10px;
  box-sizing: border-box; }
  .cart-page-controls-container .action-element {
    padding: 0 10px; }
    .cart-page-controls-container .action-element svg {
      max-width: 25px;
      width: 100%;
      height: auto;
      fill: #FFF;
      cursor: pointer; }
  .cart-page-controls-container .checkout-action-element {
    background: #fff;
    color: #383839;
    padding: 10px;
    font-weight: bold; }
    .cart-page-controls-container .checkout-action-element svg {
      max-width: 25px;
      width: 100%;
      fill: #383839;
      margin: 0 0 0 6px; }
  .cart-page-controls-container .right-controls {
    flex: 1 1;
    display: flex;
    justify-content: flex-end;
    align-items: center; }
  .cart-page-controls-container .left-controls {
    display: flex;
    align-items: center; }
    .cart-page-controls-container .left-controls .vamp-button {
      margin-right: 15px; }
  .cart-page-controls-container .checkout-button {
    max-width: 250px;
    width: 100%;
    background: #383839;
    color: #FFF; }
    .cart-page-controls-container .checkout-button:hover {
      background: #FFF;
      color: #383839; }

@charset "UTF-8";
.filters-overlay {
  position: fixed;
  background: transparent;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1; }

.product-filters .item-label {
  font-weight: bold;
  margin: 0 5px 0 0; }

.filter-values {
  display: none; }

.filters .filter-price .price-range-values {
  margin: 25px 0 0 0;
  text-align: center;
  font-weight: bold; }

.filters .filters-toggle-button .vamp-button {
  padding: 10px 15px;
  font-weight: bold; }

.filters .filter-actions {
  display: flex;
  justify-content: space-between;
  background: #383839;
  padding: 5px;
  position: fixed;
  width: 100%; }
  .filters .filter-actions svg {
    max-width: 22px;
    width: 100%;
    margin: 0;
    fill: #FFF; }
    .filters .filter-actions svg path {
      fill: #FFF; }
  .filters .filter-actions .vamp-button {
    background: none; }
    .filters .filter-actions .vamp-button:hover {
      background: none; }

.filters .top-section {
  display: flex;
  justify-content: space-between;
  margin: 0 0 15px 0; }

.filters .filters-list-container {
  box-sizing: border-box;
  max-width: 320px;
  min-width: 280px;
  box-shadow: 0 0 10px #000;
  background: #FFF;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  -webkit-transform: translateX(-400%);
          transform: translateX(-400%);
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  z-index: 99;
  color: #000; }
  .filters .filters-list-container .filters-list-inner {
    padding: 15px;
    overflow-y: auto;
    height: calc(100% - 50px);
    margin: 50px 0 0 0; }
  .filters .filters-list-container.show-filters {
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  .filters .filters-list-container ul {
    list-style: none; }
  .filters .filters-list-container .filters-list {
    padding: 0;
    text-align: left; }
    .filters .filters-list-container .filters-list > li {
      margin: 0 0 15px 0; }
  .filters .filters-list-container .filter-label {
    font-size: 1rem;
    color: #383839;
    font-weight: bold;
    cursor: pointer; }
  .filters .filters-list-container .filter-values-list {
    padding: 0 0 0 20px;
    max-height: 250px;
    overflow-y: auto; }
    .filters .filters-list-container .filter-values-list li {
      margin: 5px 0;
      padding: 10px 0;
      cursor: pointer; }
      .filters .filters-list-container .filter-values-list li.sizeCode {
        text-transform: uppercase; }
      .filters .filters-list-container .filter-values-list li.sizeName {
        text-transform: uppercase; }
      .filters .filters-list-container .filter-values-list li.active:before {
        content: '✔';
        margin-right: 3px;
        color: transparent;
        text-shadow: 0 0 0 #383839; }

.product-miniature {
  box-sizing: border-box;
  transition: box-shadow 0.3s ease-in-out;
  background: #FFF; }
  .product-miniature .sample-title {
    font-size: .9rem;
    color: #383839; }
  .product-miniature:hover {
    box-shadow: 3px 4px 18px rgba(0, 0, 0, 0.15); }
  .product-miniature.fancy-miniature {
    cursor: pointer; }
  .product-miniature svg {
    max-width: 35px;
    width: 100%;
    fill: #383839; }
  .product-miniature img {
    max-width: 100%;
    width: 100%;
    height: auto; }
  .product-miniature .image-box img {
    width: auto;
    max-height: 90vh !important; }
  .product-miniature .meta {
    padding: 10px; }
    .product-miniature .meta .title {
      text-align: center;
      font-size: .8125rem;
      color: #000;
      text-decoration: none;
      margin-bottom: 5px;
      min-height: 40px; }
    .product-miniature .meta .code {
      text-align: center;
      color: #383839;
      font-size: .9rem;
      font-weight: 700; }
    .product-miniature .meta .miniature-input-controls {
      margin-left: 10px; }
      .product-miniature .meta .miniature-input-controls svg {
        margin: 0 5px; }
    .product-miniature .meta .qty-input {
      text-align: center;
      padding: 5px 0;
      display: flex;
      justify-content: center;
      align-items: center; }
      .product-miniature .meta .qty-input label {
        font-size: .876rem;
        margin-right: 5px;
        margin-bottom: 0; }
      .product-miniature .meta .qty-input input {
        border: 1px solid #d9d9d9;
        padding: 5px;
        text-align: center;
        width: 40px;
        height: 25px;
        font-size: .875rem; }
  .product-miniature .sample-item-controls {
    color: black;
    background-color: #f1f2f6;
    text-align: center;
    width: 180px;
    margin-left: auto;
    margin-right: auto;
    padding: 6px;
    font-size: 1.1rem; }
    .product-miniature .sample-item-controls.remove {
      background: #e84848; }

.product-miniature-sample {
  padding-bottom: 30px; }

.posters-modal {
  position: fixed;
  min-height: -webkit-fill-available;
  width: 100%;
  left: 0;
  top: 0;
  background: rgba(221, 221, 221, 0.8);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  height: 100%; }
  .posters-modal .posters-modal-container {
    position: relative;
    max-width: 1200px;
    width: 100%;
    margin: auto;
    text-align: center;
    padding: 0 35px; }
    .posters-modal .posters-modal-container .slick-next:before, .posters-modal .posters-modal-container .slick-prev:before {
      color: #383839;
      font-size: 35px; }
    .posters-modal .posters-modal-container .slick-next, .posters-modal .posters-modal-container .slick-prev {
      width: 35px;
      height: 35px; }
    .posters-modal .posters-modal-container .modal-title {
      color: #383839; }
    .posters-modal .posters-modal-container .close-poster-modal {
      position: absolute;
      right: 20px;
      top: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 35px;
      height: 35px;
      background: #383839;
      border-radius: 100%;
      cursor: pointer;
      z-index: 9999; }
      .posters-modal .posters-modal-container .close-poster-modal svg {
        max-width: 20px;
        width: 100%; }
        .posters-modal .posters-modal-container .close-poster-modal svg path {
          fill: #FFF; }
  .posters-modal .poster-slider-item {
    padding: 15px;
    box-sizing: border-box; }
    .posters-modal .poster-slider-item .qty-input {
      display: flex;
      flex-direction: column; }
      .posters-modal .poster-slider-item .qty-input > label {
        margin: 15px 0 0 0; }
      .posters-modal .poster-slider-item .qty-input .miniature-input-controls {
        margin: 15px 0 0 0; }
        .posters-modal .poster-slider-item .qty-input .miniature-input-controls .tablet-control-element {
          display: inline-block; }

.cart-row {
  align-content: flex-start;
  margin-left: 0px;
  text-align: left; }
  .cart-row > .color-hex {
    margin-left: 0px;
    display: inline-block;
    width: 24px;
    height: 24px; }
  .cart-row > .color-name-box {
    display: block;
    font-size: 14px;
    vertical-align: top;
    margin-top: 0px;
    margin-left: 5px; }
  .cart-row.cart-row-edit div {
    background: #383839;
    color: #FFF;
    padding: 5px 0;
    cursor: pointer;
    max-width: 50px;
    width: 100%;
    margin: auto; }
  .cart-row .cart-image-sku-container {
    align-items: center; }
    .cart-row .cart-image-sku-container > .color-cell-container {
      width: 100%; }
      .cart-row .cart-image-sku-container > .color-cell-container > .color-name-box > .color-name-box {
        display: inline-block; }
      .cart-row .cart-image-sku-container > .color-cell-container > .color-hex {
        margin-left: 0px;
        width: 24px;
        height: 24px;
        display: block !important; }
    @media (max-width: 1200px) {
      .cart-row .cart-image-sku-container {
        min-width: 100px;
        padding-left: 70px;
        min-height: 99px; } }
  @media (max-width: 1200px) {
    .cart-row .cart-item-image {
      position: absolute;
      left: 0;
      box-shadow: 6px 6px 15px #ddd; } }
  .cart-row .cart-item-image img {
    width: auto;
    height: auto;
    max-height: 100px; }
  .cart-row .sku-container {
    width: 100%;
    text-align: center;
    margin-top: 10px;
    font-size: 18px; }
  .cart-row svg {
    max-width: 20px;
    width: 100%;
    height: auto;
    cursor: pointer; }
  .cart-row.cart-row-remove {
    cursor: pointer; }
  .cart-row.cart-row-total-qty, .cart-row.cart-row-total-price, .cart-row.cart-row-remove {
    width: 80px;
    text-align: center; }
  .cart-row.size-cell-container {
    padding-left: 20px;
    display: flex; }
    .cart-row.size-cell-container .size-item {
      text-align: center;
      margin-right: 20px; }
      .cart-row.size-cell-container .size-item.missing-stock {
        border: 2px solid red; }
      .cart-row.size-cell-container .size-item .size-name {
        margin-bottom: 5px; }
      .cart-row.size-cell-container .size-item .stock-qty {
        margin-top: 2px; }
        .cart-row.size-cell-container .size-item .stock-qty .live-stock-availability {
          display: block;
          background: red;
          color: #FFF; }

.cart-td-stock-container {
  padding: 0px 0px 0px 30px !important; }

.cart-cup-row {
  margin-bottom: 20px; }

.price-padding-cart {
  padding-top: 59px; }

.total-row-container-cart {
  margin-bottom: 34px;
  height: 45px;
  min-height: 63px; }

.cart-margin-top {
  margin-top: 6px; }

.cart-edit-button {
  background-color: #000000a6;
  color: #fff;
  margin: 17px auto 10px;
  width: 100px;
  height: 37px;
  line-height: 37px;
  font-size: 16px;
  text-align: center;
  margin-top: 0px;
  cursor: pointer; }

.cart-remove-button {
  background-color: #e84848a1;
  color: #fff;
  margin: 17px auto 15px;
  width: 100px;
  height: 37px;
  line-height: 37px;
  font-size: 16px;
  text-align: center;
  margin-top: 10px;
  cursor: pointer; }

.color-code-margin-left {
  margin-left: 5px; }

.color-code {
  display: inline-block !important; }

.total-price-text-margin {
  margin-top: 30px !important; }

.cart-simone-header {
  display: none !important; }

.cart-cup-row {
  display: none; }

.cart-simone-price {
  display: block !important; }

.color-cell-container-simone {
  width: 100% !important; }

.cart-search-container {
  display: flex;
  align-items: center;
  margin: 15px 0;
  font-size: 0.9rem; }
  .cart-search-container .label {
    font-weight: bold;
    margin: 0 10px 0 0; }
  .cart-search-container input {
    padding: 8px;
    border: 0;
    border-bottom: 1px solid #383839;
    margin: 0 0 0 10px; }
  .cart-search-container svg {
    max-width: 15px;
    height: auto;
    width: 100%; }
  .cart-search-container .found-results {
    margin: 0 0 0 10px;
    font-weight: bold; }

.cart-loader-container {
  padding: 50px;
  display: flex;
  align-items: center;
  justify-content: center; }

.cart-table {
  width: 100%;
  font-size: .813rem;
  margin-bottom: 150px;
  border-collapse: separate;
  border-spacing: 0 25px; }
  .cart-table .item-found-in-search td {
    border: 1px solid #383839; }
  @media (max-width: 1200px) {
    .cart-table {
      overflow-x: auto;
      display: block; } }

.cart-empty-msg {
  text-align: center;
  padding: 50px 15px; }

.cart-message {
  text-align: center; }
  .cart-message span {
    color: red; }

.cart-container {
  width: 100%;
  margin: auto; }

.edit-order-message {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }

.multigrid-header-row th {
  border-left: 1px solid #e9e9e9;
  border-right: 1px solid #e9e9e9;
  color: #000;
  font-weight: 300;
  text-align: center;
  padding: 25px 0;
  font-size: .813rem;
  box-sizing: border-box; }
  .multigrid-header-row th:last-child {
    border: 0; }

.multigrid-header-row .table-size-color-label {
  position: relative; }
  .multigrid-header-row .table-size-color-label .size-th-label {
    position: absolute;
    right: 15%;
    top: 15%;
    font-weight: 700; }
  .multigrid-header-row .table-size-color-label .color-th-label {
    position: absolute;
    left: 15%;
    bottom: 15%;
    font-weight: 700; }
  .multigrid-header-row .table-size-color-label .line {
    left: 0;
    top: 0;
    width: 115%;
    height: 55%;
    -webkit-transform: rotate(29deg);
            transform: rotate(29deg);
    border-bottom: 1px solid #dedede;
    position: absolute; }

.variation-stock-tooltip p {
  margin: 0 0 2px 0; }

.size-cell-container, .color-cell-container {
  text-align: center; }

.size-stock {
  font-size: 0.8rem;
  padding: 5px 0 0 0; }

.selectedColor-cell {
  border: 1px solid #383839 !important;
  border-radius: 28px !important; }

.color-cell-container {
  width: 135px;
  font-size: 0.8rem;
  margin: auto;
  padding: 0 5px;
  cursor: pointer; }
  .color-cell-container .color-hex {
    width: 35px;
    height: 15px;
    margin: 5px auto;
    border: 1px solid rgba(217, 217, 217, 0.8); }
  .color-cell-container .color-name-box .color-code {
    margin-right: 5px; }
  .color-cell-container .color-name-box span {
    display: block;
    text-transform: capitalize; }

.size-cell-container {
  padding: 15px 0; }
  .size-cell-container input {
    width: 70px;
    height: 40px;
    border: 1px solid #e1e1e1;
    padding: 5px;
    text-align: center;
    font-size: .875rem;
    transition: border-color .3s ease-in-out;
    box-sizing: border-box; }
    .size-cell-container input.availability-unavailable {
      border: 2px solid red; }
    .size-cell-container input.availability-now {
      border: 2px solid green; }
    .size-cell-container input.availability-5days {
      border: 2px solid yellow; }
    .size-cell-container input.availability-30days {
      border: 2px solid brown; }
    .size-cell-container input.input-focused {
      border: 2px solid #383839; }

.cart-input-simone {
  width: 70px;
  height: 40px;
  border: 1px solid #e1e1e1;
  padding: 5px;
  text-align: center;
  font-size: .875rem;
  transition: border-color .3s ease-in-out;
  box-sizing: border-box; }
  .cart-input-simone.availability-unavailable {
    border: 2px solid red; }
  .cart-input-simone.availability-now {
    border: 2px solid green; }
  .cart-input-simone.availability-5days {
    border: 2px solid yellow; }
  .cart-input-simone.availability-30days {
    border: 2px solid brown; }
  .cart-input-simone.input-focused {
    border: 2px solid #383839; }

.row-controls {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding: 10px 0; }
  .row-controls span {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px 0; }
  .row-controls svg {
    width: 28px;
    height: 28px;
    cursor: pointer;
    fill: #383839; }

.line-totals-container {
  text-align: center;
  font-size: .875rem; }

.multigrid-controls {
  display: flex;
  justify-content: flex-end; }
  .multigrid-controls .vamp-button {
    padding: 15px; }
  .multigrid-controls .reset-grid-button {
    max-width: 208px;
    width: 100%;
    height: 48px;
    margin-right: 25px; }
  .multigrid-controls .add-to-cart-button {
    max-width: 208px;
    width: 100%;
    height: 48px; }

.grid-totals {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px; }
  .grid-totals > div {
    margin-left: 25px; }
  .grid-totals .label {
    font-size: .813rem;
    font-weight: 400;
    color: #000;
    text-transform: lowercase;
    text-align: center;
    padding: 10px 0; }
  .grid-totals .value {
    font-size: .813rem;
    font-weight: 400;
    color: #383839;
    text-transform: lowercase;
    text-align: center;
    border-bottom: 1px solid #d9d9d9;
    padding: 10px 0; }

.tablet-controls-component {
  position: fixed;
  z-index: 999;
  left: 0;
  bottom: 0;
  width: 100%; }
  .tablet-controls-component .table-controls-container {
    padding: 10px;
    background: #383839;
    display: flex;
    justify-content: center; }
    .tablet-controls-component .table-controls-container .tablet-control-element {
      display: inline-block;
      margin: 0 15px;
      cursor: pointer; }
  .tablet-controls-component svg {
    max-width: 45px;
    height: auto;
    width: 100%;
    fill: #FFF; }

.multi-grid-wrapper .plus-size-notice {
  margin: 15px 0 0 0;
  text-align: right;
  color: #383839;
  font-weight: bold;
  font-size: 0.9rem; }

.multi-grid-wrapper .multigrid-table {
  width: 100%;
  margin-bottom: 50px; }
  .multi-grid-wrapper .multigrid-table tbody {
    width: 100%; }

.multi-grid-wrapper .product-meta {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 15px 0;
  font-size: 0.875rem; }

.cta-button-container {
  margin-top: 25px;
  display: block;
  float: right;
  width: 100%; }

.total-for-all-colors-container {
  margin-top: 25px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  display: block;
  float: right;
  width: 200px; }
  .total-for-all-colors-container > p {
    margin-bottom: 0; }
  .total-for-all-colors-container > p > span {
    font-size: 12px;
    font-weight: 300;
    display: block;
    margin-top: 14px; }

.reset-grid-button-simone {
  margin-left: auto;
  display: inline-block;
  width: 208px;
  max-width: 270px !important;
  height: 48px;
  font-size: 18px !important;
  line-height: 36px !important;
  padding-top: 5px !important;
  float: right; }

.order-grid-button-simone {
  display: inline-block;
  width: 208px;
  max-width: 270px !important;
  height: 48px;
  font-size: 18px !important;
  line-height: 36px !important;
  padding-top: 5px !important;
  float: right;
  margin-left: 24px; }

.color-container {
  padding: 6px 5px 6px 10px;
  border: solid 1px black;
  margin-bottom: 25px;
  display: block;
  width: 170px;
  cursor: pointer; }

.color-selector-circle-container {
  display: inline-block;
  float: left;
  height: 24px; }

.color-selector-circle {
  width: 24px;
  border-radius: 12px;
  height: 24px; }

.color-selector-info {
  display: inline-block; }

.color-selected-info-text {
  color: black;
  font-size: 14px;
  font-weight: 400;
  margin-left: 6px;
  margin-bottom: 0px; }

.size-selector-container {
  padding: 20px 30px 30px 20px;
  border: solid #DDDDDD 1px;
  margin-left: 56px;
  min-width: 778px; }

.color-container-div {
  display: inline-block;
  vertical-align: top; }

.passive-color {
  opacity: 0.4; }

.color-list-header {
  color: #606060;
  font-size: 14px;
  margin-top: 15px; }

.simone-main-container {
  padding-top: 24px;
  border-top: 1px solid #D9D9D9;
  overflow: auto; }

.size-selector-div {
  display: inline-block;
  width: calc(100% - 170px); }

.size-selector-header {
  display: block;
  width: 100%;
  margin-bottom: 15px; }

.size-text {
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  width: 100%;
  margin-bottom: 0px; }

.size-container {
  display: inline-block;
  text-align: center;
  width: calc(((100% - 70px) / 5)); }

.size-container-icon {
  display: inline-block;
  text-align: center; }

.size-icon {
  width: 35px;
  margin-right: 15px; }

.cup-row {
  width: 100%;
  height: 65px;
  margin-bottom: 25px; }

.cup-text {
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  width: 100%;
  margin-bottom: 0px; }

.cup-text-container {
  width: 35px;
  margin-right: 15px;
  display: inline-block;
  vertical-align: top;
  margin-top: 11px; }

.size-input {
  width: 72px;
  height: 45px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  text-align: center;
  border: solid 1px #DDDDDD; }

.size-stock-text {
  font-size: 12px;
  color: black;
  display: block;
  text-align: center;
  margin-bottom: 0px; }

.total-text {
  color: #000;
  font-size: 16px;
  font-weight: bold;
  margin-left: 0;
  margin-bottom: 0;
  width: 65px;
  text-align: center; }

.passive-color {
  color: #DDDDDD; }

.total-col-sub-container {
  width: 170px;
  padding-left: 25px;
  display: inline-block;
  border-left: solid 1px #D9D9D9;
  vertical-align: top; }

.size-selector-sub-container {
  display: inline-block;
  width: calc( 100% - 170px); }

.total-price-text {
  width: 65px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0;
  padding-top: 10px; }
  .total-price-text > span {
    font-size: 12px;
    font-weight: 300;
    margin-top: 14px;
    display: none; }

.totat-price-container {
  display: inline-block; }

.total-number-input-container {
  display: inline-block;
  vertical-align: top;
  margin-left: 4px; }

.total-row-container {
  margin-bottom: 25px;
  height: 65px; }

.total-text-container {
  margin-bottom: 15px; }

.plus-minus-container {
  margin-top: 6px; }

.total-minus-cta {
  display: inline-block;
  width: 12px; }

.total-plus-cta {
  display: inline-block;
  width: 11px; }

.total-cta-container {
  cursor: pointer;
  height: 100%;
  display: inline-block; }
  .total-cta-container > img {
    width: 23px; }

.plus-cta-icon {
  margin-left: 10px;
  width: 31px; }

.margin-bottom-zero {
  margin-bottom: 0; }

.quickview-component {
  position: fixed;
  top: 0;
  height: 100vh;
  background: #fff;
  box-shadow: -5px -10px 16px rgba(0, 0, 0, 0.15);
  max-width: 90vw;
  width: 100%;
  padding: 50px;
  z-index: 999;
  right: -110%;
  transition: right .3s ease-in-out;
  overflow: auto; }
  .quickview-component .product-link a {
    display: inline-block;
    color: #919191; }
  .quickview-component .multigrid-table .line-totals {
    padding: 0 15px; }
  .quickview-component.show {
    right: 0; }
  .quickview-component .delivery-week {
    text-align: right;
    margin: 10px 0;
    font-size: 1rem; }
    .quickview-component .delivery-week .label {
      font-weight: bold;
      color: #383839;
      margin: 0 10px 0 0; }
    .quickview-component .delivery-week .value {
      font-weight: bold; }
  .quickview-component .quickview-component-container {
    position: relative; }
    .quickview-component .quickview-component-container .quickview-close {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 9; }
  .quickview-component .product-quickview-wrapper {
    display: flex;
    align-items: center; }
    .quickview-component .product-quickview-wrapper .image-container {
      width: 10%;
      align-self: start;
      margin-top: 152px;
      margin-right: 20px; }
      .quickview-component .product-quickview-wrapper .image-container img {
        max-width: 100%;
        height: auto; }
    .quickview-component .product-quickview-wrapper .product-data-container {
      width: 90%; }
  .quickview-component .product-header {
    line-height: 1;
    font-size: 1.375rem;
    letter-spacing: .02rem;
    color: #000;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .quickview-component .product-header .right-container .product-price-container {
      display: flex; }
      .quickview-component .product-header .right-container .product-price-container .label {
        margin-right: 10px; }
      .quickview-component .product-header .right-container .product-price-container .lds-roller {
        max-width: 10px; }
      .quickview-component .product-header .right-container .product-price-container .value {
        font-size: 1.4rem;
        color: #383839;
        font-weight: 700; }
        .quickview-component .product-header .right-container .product-price-container .value.old-price {
          text-decoration: line-through;
          color: #bfbfbf;
          margin-right: 5px;
          font-size: 1.2rem; }
    .quickview-component .product-header .left-container > div {
      margin: 0 0 15px 0; }
    .quickview-component .product-header .left-container .sku .label {
      color: #919191; }

@media (max-width: 992px) {
  .plus-size-notice {
    font-size: 0.875rem; }
  .quickview-component {
    padding: 35px; }
    .quickview-component .delivery-week {
      margin: 0; }
    .quickview-component .product-header {
      flex-wrap: wrap;
      font-size: 1rem;
      margin: 0 0 15px 0; }
      .quickview-component .product-header .right-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        flex-wrap: wrap; }
        .quickview-component .product-header .right-container .product-price-container .value {
          font-size: 1.1rem; }
    .quickview-component .product-quickview-wrapper .multigrid-table {
      display: block;
      overflow-x: auto; }
    .quickview-component .product-quickview-wrapper .product-data-container {
      flex: 1 1; }
    .quickview-component .product-quickview-wrapper .image-container {
      min-width: 90px;
      flex: 1 1; } }

@media (max-width: 650px) {
  .quickview-component .product-quickview-wrapper .product-data-container {
    margin-bottom: 200px; }
  .tablet-controls-component {
    bottom: 0;
    width: 100%;
    left: 0; }
    .tablet-controls-component .table-controls-container {
      width: 100%;
      display: flex;
      justify-content: center; }
      .tablet-controls-component .table-controls-container svg {
        max-width: 35px; } }

@media (max-width: 560px) {
  .plus-size-notice {
    font-size: 0.875rem;
    text-align: center; }
  .quickview-component {
    padding: 25px; }
    .quickview-component .multigrid-controls {
      flex-direction: column-reverse; }
      .quickview-component .multigrid-controls .vamp-button {
        margin: 0 0 10px 0;
        width: 100%;
        max-width: 100%; }
    .quickview-component .product-quickview-wrapper {
      flex-wrap: wrap; }
    .quickview-component .quickview-close svg {
      max-width: 35px;
      width: 100%;
      height: auto; }
    .quickview-component .product-quickview-wrapper .image-container {
      width: 100%;
      min-width: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 0 25px 0; }
      .quickview-component .product-quickview-wrapper .image-container img {
        max-width: 110px;
        text-align: center;
        margin: auto; } }

.simone-logo-quick-buy-cart {
  width: 210px;
  margin-bottom: 5px; }

.checkout-error-response {
  padding: 5px;
  background: #f98a8a;
  font-size: 0.875rem; }
  .checkout-error-response p {
    margin: 0 0 5px 0; }
  .checkout-error-response li strong {
    margin: 0 5px 0 0; }

.login-page {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: -webkit-fill-available; }
  .login-page input {
    box-sizing: border-box; }
  .login-page .login-form-error {
    text-align: center;
    font-weight: bold;
    color: red; }
  .login-page .login-logo {
    text-align: center;
    margin-bottom: 25px; }
    .login-page .login-logo svg {
      max-width: 220px;
      height: auto;
      width: 100%; }
  @media (max-width: 600px) {
    .login-page .login-banner {
      display: none; } }
  .login-page .welcome-notice {
    font-size: 16px;
    margin-bottom: 35px; }
    .login-page .welcome-notice p {
      margin: 0 0 5px 0; }
  .login-page .login-form {
    max-width: 500px;
    width: 100%; }
    .login-page .login-form .password-form-group .input-container {
      position: relative;
      width: 100%; }
      .login-page .login-form .password-form-group .input-container input {
        width: 100%;
        padding-right: 45px; }
    .login-page .login-form .password-form-group .show-password-toggle {
      position: absolute;
      right: 10px;
      top: 50%;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
      display: flex;
      align-items: center;
      cursor: pointer; }
    .login-page .login-form .password-form-group svg {
      max-width: 25px;
      width: 100%;
      height: auto; }
    .login-page .login-form .form-group {
      display: flex;
      flex-direction: column; }
      .login-page .login-form .form-group label {
        font-weight: bold;
        text-transform: lowercase;
        margin-bottom: 5px;
        font-size: 15px; }
    .login-page .login-form .submit-button {
      margin: 15px 0 0 0;
      background: #383839;
      color: #FFF;
      font-size: 18px;
      padding: 12px;
      cursor: pointer; }
  .login-page .reauth-pin-container {
    text-align: center; }
    .login-page .reauth-pin-container p {
      font-size: 0.975rem;
      font-weight: bold; }
  .login-page input {
    padding: 8px;
    border: 1px solid #DDD; }

.entry-message-underline {
  text-decoration: underline; }

.return-password-form {
  margin: 15px 0;
  font-size: 0.875rem;
  text-align: center; }
  .return-password-form header h4 {
    cursor: pointer;
    font-size: 0.875rem;
    font-weight: bold; }
  .return-password-form .err-message {
    color: red; }
  .return-password-form .form-group {
    display: flex;
    flex-direction: column;
    text-align: left; }
  .return-password-form button {
    border: 0;
    background: #383839;
    color: #FFF;
    font-size: 0.9rem;
    padding: 5px;
    cursor: pointer; }

.sidebar-list {
  padding: 0;
  margin: 0;
  list-style: none; }
  @media (max-width: 768px) {
    .sidebar-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center; }
      .sidebar-list > li {
        width: 33.33%;
        display: flex;
        align-items: center;
        margin-right: 0; } }
  .sidebar-list > li {
    margin-bottom: 15px; }
    @media (max-width: 768px) {
      .sidebar-list > li {
        margin: 0 0 10px 0 !important;
        width: 50%; }
        .sidebar-list > li a, .sidebar-list > li span {
          padding: 10px !important; } }

.account-sidebar-item {
  display: block;
  text-align: center; }
  .account-sidebar-item svg {
    max-height: 25px;
    width: auto; }
  .account-sidebar-item a, .account-sidebar-item div, .account-sidebar-item span {
    text-decoration: none;
    font-size: 0.9rem;
    color: #383839;
    text-align: center;
    display: block;
    width: 100%;
    padding: 8px 15px;
    box-sizing: border-box;
    cursor: pointer; }
  .account-sidebar-item:hover a, .account-sidebar-item:hover span {
    text-decoration: none; }

.panel-header {
  background: #383839;
  color: #FFF;
  padding: 15px;
  margin: 0 0 15px 0;
  font-weight: bold;
  text-align: left; }

.panel-field svg {
  max-width: 20px;
  width: 100%;
  height: auto;
  margin: 0 0 0 10px; }

.panel-field .edit-input-form input {
  padding: 4px; }

.panel-field .edit-input-form button {
  background: #000;
  color: #FFF;
  padding: 5px 10px;
  border: 0;
  border-radius: 0;
  margin: 0 0 0 5px;
  cursor: pointer; }

.customer-settings {
  border-top: 1px solid #0a0a0a; }
  .customer-settings .form-row {
    margin: 10px 0;
    display: flex;
    align-items: center;
    font-size: 14px; }
    .customer-settings .form-row .label {
      margin: 0 5px 0 0; }
    .customer-settings .form-row input {
      margin: 0 0 0 0; }

.customer-info-panel-points-notice {
  text-align: center;
  background: #383839;
  color: #FFF;
  font-size: 1rem;
  padding: 5px;
  margin: 0 0 5px 0; }
  .customer-info-panel-points-notice p {
    margin: 0 0 5px 0; }

.edit-field-container {
  display: flex;
  flex-direction: column; }

.account-balances-container {
  display: flex;
  justify-content: center;
  align-items: center; }
  .account-balances-container > div {
    padding: 0 10px; }
    .account-balances-container > div.account-balance .label {
      color: #c16780; }
    .account-balances-container > div .label {
      padding: 0 5px 0 0; }

.salesmen-row h5, .salesmen-row ul {
  font-size: 0.875rem; }

.salesmen-row h5 {
  font-weight: bold; }

.personal-content {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 -15px; }
  .personal-content .panel-field {
    width: 50%;
    padding: 0 15px; }
    @media (max-width: 600px) {
      .personal-content .panel-field {
        width: 100%; } }

.panel {
  width: 100%; }

.salesman-infos {
  width: 100%; }

.account-panel-error {
  color: red; }

.panel-body {
  padding: 15px; }
  .panel-body .panel-row {
    margin-bottom: 25px; }
    .panel-body .panel-row header {
      color: #383839;
      border-bottom: 1px solid #383839;
      width: 100%;
      display: block;
      margin-bottom: 15px;
      font-weight: bold; }
    .panel-body .panel-row .panel-field {
      display: flex;
      flex-direction: column;
      margin: 0 0 10px 0;
      box-sizing: border-box;
      justify-content: center; }
      .panel-body .panel-row .panel-field label {
        font-size: .875rem;
        color: #383839;
        border-bottom: 1px solid #d9d9d9;
        margin: 0 0 5px 0; }

.customer-list-item {
  text-align: center;
  font-size: 0.9rem; }
  .customer-list-item .customer-item-box {
    min-height: 100px;
    padding: 15px;
    box-shadow: 3px 4px 18px rgba(0, 0, 0, 0.15);
    transition: -webkit-transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out; }
    .customer-list-item .customer-item-box:hover {
      -webkit-transform: translateY(-5px);
              transform: translateY(-5px); }
    .customer-list-item .customer-item-box svg {
      margin-bottom: 5px; }
    .customer-list-item .customer-item-box > div {
      margin-bottom: 5px;
      word-break: break-all; }
    .customer-list-item .customer-item-box .customer-name {
      font-weight: 700;
      color: #383839;
      min-height: 40px; }
    .customer-list-item .customer-item-box .select-button {
      background: #383839;
      color: #fff;
      width: 100%;
      padding: 10px;
      font-weight: 700;
      text-align: center;
      font-size: .8rem;
      margin: 15px 0 0 0;
      box-sizing: border-box;
      cursor: pointer; }

.customer-access-table-actions {
  display: flex;
  align-items: center;
  justify-content: center; }

.customers-list-container {
  position: relative; }

.access-list-item .customer-name {
  padding: 40px 0;
  background: #383839 !important; }
  .access-list-item .customer-name .value {
    position: absolute;
    left: 0;
    max-width: 150px;
    width: 100%;
    border-right: 2px solid #383839;
    padding: 20px;
    -webkit-transform: translateY(-40px);
            transform: translateY(-40px);
    background: #383839;
    color: #FFF; }

.access-list-item .customer-item-box {
  flex-direction: row;
  display: flex;
  flex-wrap: wrap; }
  .access-list-item .customer-item-box > div {
    padding: 5px;
    margin: 0 15px 0 0;
    text-align: left; }
    .access-list-item .customer-item-box > div .value {
      text-align: center; }
      @media (max-width: 768px) {
        .access-list-item .customer-item-box > div .value {
          text-align: left; } }
    @media (max-width: 768px) {
      .access-list-item .customer-item-box > div {
        width: 33.33%;
        box-sizing: border-box;
        padding: 0 10px;
        margin: 0 0 15px 0; } }
    @media (max-width: 580px) {
      .access-list-item .customer-item-box > div {
        width: 50%; } }
    .access-list-item .customer-item-box > div label {
      margin: 0 0 5px 0;
      font-weight: bold;
      color: #383839;
      height: 20px;
      display: inline-block;
      border-bottom: 1px solid #383839;
      padding: 5px 0;
      text-align: left; }
    .access-list-item .customer-item-box > div.customer-seasons {
      width: 100%; }

.access-list-item .edit-field-container .label {
  display: none; }

.access-list-item .edit-field-container svg {
  max-width: 20px;
  width: 100%;
  height: auto;
  margin: 0 0 0 10px; }

.access-list-item .edit-field-container .edit-input-form input {
  padding: 4px; }

.access-list-item .edit-field-container .edit-input-form button {
  background: #000;
  color: #FFF;
  padding: 5px 10px;
  border: 0;
  border-radius: 0;
  margin: 0 0 0 5px;
  cursor: pointer; }

.access-list-item .yes-value {
  background: green;
  color: #FFF;
  display: inline-block; }

.access-list-item .no-value {
  background: red;
  color: #FFF;
  display: inline-block; }

.access-list-item .value span {
  padding: 4px; }

.access-list-item .customer-send-access {
  width: auto !important; }
  .access-list-item .customer-send-access .value {
    display: flex; }
    .access-list-item .customer-send-access .value input {
      padding: 6px;
      flex: 1 1; }

.customers-access-table {
  overflow-x: auto;
  display: block;
  width: 100%; }
  .customers-access-table td {
    min-width: 150px;
    border-bottom: 1px solid #383839;
    border-right: 1px solid #383839;
    text-align: center; }
  .customers-access-table th {
    text-align: center; }
  .customers-access-table tr:nth-child(odd) td {
    background: #38383912; }

.no-customers-found-msg {
  text-align: center;
  font-size: 0.875rem; }

.customers-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
  box-sizing: border-box; }
  .customers-list .customer-list-item {
    width: 33.33%;
    padding: 0 15px;
    margin-bottom: 15px;
    box-sizing: border-box; }
    @media (max-width: 860px) {
      .customers-list .customer-list-item {
        width: 50%; } }
    @media (max-width: 640px) {
      .customers-list .customer-list-item {
        width: 100%; } }

.panel-top-controls {
  padding: 15px;
  border: 1px solid #d9d9d9;
  margin-bottom: 25px;
  display: flex;
  justify-content: space-between; }
  @media (max-width: 600px) {
    .panel-top-controls {
      flex-wrap: wrap; } }
  .panel-top-controls .panel-search {
    padding: 5px;
    position: relative; }
    @media (max-width: 600px) {
      .panel-top-controls .panel-search {
        margin-bottom: 15px; } }
    .panel-top-controls .panel-search svg {
      position: absolute;
      top: 50%;
      left: 0;
      -webkit-transform: translate(0, -50%);
              transform: translate(0, -50%);
      max-width: 20px;
      height: auto; }
    .panel-top-controls .panel-search input {
      font-size: .935rem;
      border: 0;
      border-bottom: 1px solid #d9d9d9;
      padding: 10px 10px 10px 30px; }

.customers-access-page {
  width: 100%; }
  .customers-access-page .panel-top-controls {
    display: flex;
    flex-direction: column; }
    .customers-access-page .panel-top-controls .panel-search input {
      width: 100%;
      box-sizing: border-box; }
    .customers-access-page .panel-top-controls .customer-access-panel-filters {
      display: flex;
      list-style: none;
      margin: 0;
      padding: 15px 0;
      font-size: 0.9rem; }
      @media (max-width: 768px) {
        .customers-access-page .panel-top-controls .customer-access-panel-filters {
          flex-wrap: wrap; }
          .customers-access-page .panel-top-controls .customer-access-panel-filters li {
            width: 50%;
            box-sizing: border-box;
            margin: 0 0 15px 0 !important; } }
      .customers-access-page .panel-top-controls .customer-access-panel-filters li {
        margin: 0 15px 0 0;
        padding: 0 5px 0 0; }
        .customers-access-page .panel-top-controls .customer-access-panel-filters li span {
          margin: 0 5px 0 0; }
  .customers-access-page .customers-list {
    flex-direction: column; }
    .customers-access-page .customers-list .customer-list-item {
      width: 100%; }

.info-table table, .info-table tbody {
  width: 100%; }

.info-table th {
  background: #383839;
  color: #FFF;
  font-size: 14px; }

.info-table td {
  font-size: 15px; }

.info-table th, .info-table td {
  padding: 6px;
  border-bottom: 1px solid #383839; }

.info-table .table-input-filter {
  margin: 5px 0 0 0;
  height: auto;
  font-size: 0.875rem;
  padding: 5px;
  border-radius: 0;
  border: 0;
  line-height: 1.1; }

.info-table .pagination {
  margin: 15px 0; }
  .info-table .pagination > span {
    margin: 0 15px; }
  .info-table .pagination button {
    background: #383839;
    color: #FFF;
    border: 0;
    margin: 0 5px 0 0; }

.customers-balances-page {
  width: 100%; }

.page-header {
  text-align: center;
  color: #383839;
  margin: 0 0 25px 0; }
  .page-header h1 {
    font-size: 21px; }

.orders-table-notice {
  text-align: center;
  font-size: 0.875rem; }

.panel-menu {
  display: flex;
  align-items: center; }
  .panel-menu ul {
    list-style: none;
    padding: 0;
    margin: 0; }
    .panel-menu ul a {
      background: #383839;
      color: #FFF;
      padding: 8px;
      text-decoration: none;
      font-size: 0.875rem;
      font-weight: bold;
      display: inline-block; }

.customers-panel {
  text-align: center; }

.form-section-title {
  background: #383839;
  width: 100%;
  padding: 15px;
  margin-bottom: 15px;
  color: #fff;
  box-sizing: border-box; }

.form-section-content {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
  padding: 0 15px; }

.form-element {
  margin: 0 0 15px 0;
  width: 50%;
  padding: 0 15px;
  box-sizing: border-box; }
  .form-element.checkbox-element {
    display: flex;
    align-items: center; }
  .form-element.required label:after {
    content: '*';
    color: red; }
  .form-element .errors {
    color: red;
    font-size: 0.875rem; }
  .form-element label {
    max-width: 180px;
    width: 100%;
    display: inline-block;
    margin-bottom: 5px;
    color: #383839;
    min-width: 80px;
    font-size: 0.875rem; }
  .form-element input, .form-element select {
    border: 1px solid #d9d9d9;
    width: 100%;
    padding: 8px;
    font-size: .875rem;
    box-sizing: border-box; }

.modal-component {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(21, 16, 16, 0.6);
  z-index: 9999;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box; }
  .modal-component .modal-container {
    box-sizing: border-box;
    position: relative;
    max-width: 600px;
    width: 100%;
    margin: auto;
    background: #FFF;
    padding: 25px; }
    .modal-component .modal-container header {
      margin: 0 0 25px 0; }
      .modal-component .modal-container header h1 {
        margin: 0;
        font-size: 1.2rem;
        color: #383839; }
    .modal-component .modal-container .customers-list {
      width: 100%;
      margin: 0; }
      .modal-component .modal-container .customers-list ul {
        margin: 0;
        padding: 0;
        width: 100%;
        list-style: none;
        box-sizing: border-box; }
        .modal-component .modal-container .customers-list ul li {
          margin: 0 0 15px 0;
          display: flex;
          justify-content: space-between; }
    .modal-component .modal-container .modalClose {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      right: 0;
      top: 0;
      background: #383839;
      color: #FFF;
      border-radius: 50%;
      width: 35px;
      height: 35px; }
      .modal-component .modal-container .modalClose svg {
        padding: 10px;
        width: 100%;
        max-width: 25px;
        fill: #FFF; }
        .modal-component .modal-container .modalClose svg path {
          fill: #FFF; }

.customer-create-form-container {
  width: 100%; }

.fullscreen-loader {
  padding: 25px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: -webkit-fill-available;
  z-index: 99;
  background: rgba(56, 56, 57, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box; }
  .fullscreen-loader .loader-message {
    text-align: center;
    font-size: 2.5rem;
    color: #FFF;
    font-weight: bold;
    margin-bottom: 50px;
    word-break: break-word;
    display: block; }
    @media (max-width: 768px) {
      .fullscreen-loader .loader-message {
        font-size: 1.8rem; } }
  .fullscreen-loader .lds-ripple {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px; }
  .fullscreen-loader .lds-ripple div {
    position: absolute;
    border: 4px solid #fff;
    opacity: 1;
    border-radius: 50%;
    -webkit-animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
            animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite; }
  .fullscreen-loader .lds-ripple div:nth-child(2) {
    -webkit-animation-delay: -0.5s;
            animation-delay: -0.5s; }

@-webkit-keyframes lds-ripple {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1; }
  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0; } }

@keyframes lds-ripple {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1; }
  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0; } }

.date-ranges {
  margin: 15px 0; }
  .date-ranges ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex; }
    .date-ranges ul li {
      margin: 0 15px 0 0;
      font-size: 0.9rem;
      cursor: pointer;
      font-weight: bold;
      border: 1px solid;
      padding: 0 10px 0 10px; }

.account-page .special-label {
  color: #af6767; }

.account-page .panel-header {
  background: #FFF;
  color: #383839; }

@media (max-width: 768px) {
  .account-page {
    flex-wrap: wrap; } }

.account-page.role-admin, .account-page.role-salesman {
  flex-wrap: wrap; }
  .account-page.role-admin .account-sidebar, .account-page.role-salesman .account-sidebar {
    max-width: 100%;
    border-right: 0 !important; }
    .account-page.role-admin .account-sidebar .sidebar-list, .account-page.role-salesman .account-sidebar .sidebar-list {
      display: flex;
      margin: 0 0 15px 0; }
      .account-page.role-admin .account-sidebar .sidebar-list li, .account-page.role-salesman .account-sidebar .sidebar-list li {
        margin: 0 0 0 0; }
        .account-page.role-admin .account-sidebar .sidebar-list li a, .account-page.role-salesman .account-sidebar .sidebar-list li a {
          padding: 15px; }

.account-page .account-sidebar {
  display: flex;
  align-items: center;
  padding: 0 15px;
  width: 100%; }
  @media (max-width: 768px) {
    .account-page .account-sidebar {
      max-width: 100%; } }
  .account-page .account-sidebar .sidebar-list {
    width: 100%;
    display: flex;
    justify-content: center; }

.account-page .account-panel {
  width: 100%;
  padding: 0 15px;
  display: flex;
  justify-content: center; }

.account-page .account-panel-components .panel-body {
  background: #f8fbff; }

.welcome-page .welcome-menu-list {
  max-width: 1000px;
  margin: 100px auto;
  display: flex;
  justify-content: space-between;
  list-style: none; }
  @media (max-width: 768px) {
    .welcome-page .welcome-menu-list {
      flex-direction: column; } }
  .welcome-page .welcome-menu-list .list-item-element {
    text-align: center;
    box-shadow: 0 0 10px #383839;
    max-width: 300px;
    width: 100%;
    height: 100%;
    margin: auto; }
    .welcome-page .welcome-menu-list .list-item-element.element-no-style {
      box-shadow: none; }
  .welcome-page .welcome-menu-list .label {
    font-size: 1.1rem;
    padding: 15px 5px;
    text-decoration: none;
    color: #383839;
    min-height: 83px;
    align-content: space-evenly; }
  .welcome-page .welcome-menu-list > a {
    padding: 25px 15px; }
    .welcome-page .welcome-menu-list > a svg {
      max-width: 100px;
      width: 100%;
      height: auto; }
    .welcome-page .welcome-menu-list > a img {
      width: 100%;
      height: auto; }
      @media (max-width: 768px) {
        .welcome-page .welcome-menu-list > a img {
          max-width: 400px; } }
    .welcome-page .welcome-menu-list > a:hover {
      text-decoration: none; }

.checkout-section {
  margin-bottom: 35px; }

.checkout-section-header {
  background: #fff;
  padding: 10px 15px;
  font-size: 1rem;
  color: #383839;
  border-bottom: 1px solid #383839;
  display: flex;
  align-items: center; }
  .checkout-section-header .section-number {
    border: 1px solid #383839;
    border-radius: 100%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center; }
  .checkout-section-header .section-title {
    margin-left: 25px;
    font-weight: 700; }

.checkout-section-content {
  background: #fff;
  padding: 15px;
  margin-bottom: 20px;
  font-size: 0.95rem; }
  .checkout-section-content .label {
    color: #383839;
    min-width: 80px; }

.checkout-address-box {
  border: 1px solid #d9d9d9;
  padding: 15px;
  margin-bottom: 15px; }

.checkout-page .checkout-error {
  text-align: center;
  color: red;
  font-size: 1.1rem; }

.checkout-page .over-shipping-notice {
  text-align: center;
  padding: 15px 0;
  font-weight: bold; }

.checkout-page .points-top-notice {
  text-align: center;
  background: #383839;
  color: #FFF;
  padding: 15px; }
  .checkout-page .points-top-notice h3 {
    font-size: 1rem;
    margin: 0 0 5px 0; }
  .checkout-page .points-top-notice h4 {
    font-size: 0.875rem;
    margin: 0; }

.checkout-page .customer-points-intro {
  display: flex;
  align-items: center;
  margin: 0 0 10px 0;
  background: #383839;
  color: #FFF;
  padding: 10px; }
  .checkout-page .customer-points-intro p {
    margin: 0;
    width: auto !important; }
  .checkout-page .customer-points-intro svg {
    max-width: 25px;
    height: auto;
    width: 100%;
    margin: 0 0 0 8px;
    fill: #FFF; }

.hidden {
  display: none !important; }

.checkout-form .form-group {
  box-sizing: border-box;
  display: flex;
  margin: 0 0 5px 0; }
  .checkout-form .form-group.error input, .checkout-form .form-group.error select {
    border: 2px solid red; }
  @media (max-width: 600px) {
    .checkout-form .form-group {
      margin-bottom: 15px; }
      .checkout-form .form-group span {
        display: block; } }

.checkout-form .value {
  width: 100%; }

.checkout-form .label {
  max-width: 150px;
  width: 100%;
  display: inline-block;
  margin-bottom: 5px; }
  .checkout-form .label.required:before {
    content: '*';
    color: red; }

.checkout-form select {
  border: 1px solid #d9d9d9;
  width: 100%;
  padding: 8px; }

.checkout-form .order-review .form-group {
  display: flex;
  justify-content: space-between; }
  .checkout-form .order-review .form-group .value {
    font-size: 1.1rem;
    font-weight: bold;
    color: #383839; }

.checkout-form .extra-order-data .checkout-section-content {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px; }
  .checkout-form .extra-order-data .checkout-section-content .form-group {
    padding: 0 15px;
    width: 50%;
    margin-bottom: 15px; }
    @media (max-width: 640px) {
      .checkout-form .extra-order-data .checkout-section-content .form-group {
        width: 100%; } }
    .checkout-form .extra-order-data .checkout-section-content .form-group.textarea-group {
      width: 100%;
      flex-direction: column; }
      .checkout-form .extra-order-data .checkout-section-content .form-group.textarea-group .label, .checkout-form .extra-order-data .checkout-section-content .form-group.textarea-group .value {
        width: 100%;
        display: block; }
        .checkout-form .extra-order-data .checkout-section-content .form-group.textarea-group .label textarea, .checkout-form .extra-order-data .checkout-section-content .form-group.textarea-group .value textarea {
          width: 100%;
          padding: 8px;
          box-sizing: border-box; }
      .checkout-form .extra-order-data .checkout-section-content .form-group.textarea-group .vamp-button {
        margin: auto; }
        .checkout-form .extra-order-data .checkout-section-content .form-group.textarea-group .vamp-button:hover {
          background: #FFF !important; }
        .checkout-form .extra-order-data .checkout-section-content .form-group.textarea-group .vamp-button svg {
          margin: auto;
          max-width: 25px;
          width: 100%;
          height: auto; }

.checkout-form .customer-points {
  width: 100% !important;
  display: block; }
  .checkout-form .customer-points p {
    width: 100%; }

.checkout-submit {
  display: flex;
  align-self: center;
  justify-content: center; }
  .checkout-submit .vamp-button {
    max-width: 240px;
    width: 100%;
    background: #383839;
    color: #FFF;
    font-size: 1.4rem; }

.checkout-form {
  max-width: 800px;
  width: 100%;
  margin: auto; }

.thank-you-page {
  text-align: center;
  padding: 50px 0; }
  .thank-you-page .header-message svg {
    max-width: 150px;
    height: auto;
    width: 100%; }
  .thank-you-page .result-msg.notice-msg {
    color: #b10d0d; }
  .thank-you-page .result-msg svg {
    max-width: 60px;
    height: auto;
    width: 100%; }
  .thank-you-page .order-summary-table {
    text-align: center;
    max-width: 600px;
    margin: 50px auto; }
    .thank-you-page .order-summary-table h3 {
      font-size: 1.5rem;
      padding: 0 0 5px 0;
      margin: 0 0 5px 0; }
    .thank-you-page .order-summary-table table {
      width: 100%; }
      .thank-you-page .order-summary-table table th {
        background: #383839;
        color: #FFF; }

.orders-pdf-link {
  background: none;
  border: 0; }
  .orders-pdf-link:focus {
    outline: none; }

.order-details-btn a {
  text-decoration: none; }

.orders-table-component {
  width: 100%; }
  .orders-table-component .order-edit-button {
    cursor: pointer; }
  .orders-table-component .orders-table td, .orders-table-component .orders-table th {
    text-align: left; }

.orders-pdf-link svg {
  max-width: 35px;
  width: 100%;
  height: auto; }

.customer-orders-panel {
  width: 100%; }
  .customer-orders-panel .dates-inputs {
    display: flex; }
    .customer-orders-panel .dates-inputs > .form-group {
      margin-right: 15px; }

.filter-orders-with-sessions {
  padding-bottom: 30px; }

.filter-orders-with-sessions-label {
  font-size: 13px; }

.customer-statements {
  width: 100%; }
  .customer-statements .panel-filters .right-container > * {
    margin: 0 0 15px 0; }
  .customer-statements .panel-filters .right-container .infos-print-pagination h4 {
    font-size: 0.9rem; }
  .customer-statements .panel-filters .right-container .infos-print-pagination p {
    font-size: 1.1rem; }
  .customer-statements .panel-filters .right-container .infos-print-pagination ul {
    list-style: none;
    padding: 0;
    margin: 0; }
    .customer-statements .panel-filters .right-container .infos-print-pagination ul li {
      margin: 0 10px 0 0;
      padding: 5px;
      cursor: pointer;
      display: inline-block;
      width: 35px;
      text-align: center;
      font-size: 14px;
      background: #383839;
      color: #fff; }
  .customer-statements .panel-filters .left-container {
    display: block;
    margin: 0 0 15px 0; }
    .customer-statements .panel-filters .left-container > .form-group {
      margin: 0 15px 0 0; }
  .customer-statements .form-group label {
    margin: 0 10px 0 0; }
  .customer-statements .active-page {
    background: #3f4c7a !important; }

.panel-filters .date-filters {
  display: flex;
  margin: 0 0 15px 0; }
  .panel-filters .date-filters > .form-group {
    margin: 0 10px 0 0; }

.panel-filters .form-group {
  display: flex;
  flex-direction: column;
  font-size: 13px; }
  .panel-filters .form-group label {
    font-weight: bold;
    margin: 0 0 5px 0; }
  .panel-filters .form-group input {
    border: 1px solid #b9b9b9;
    padding: 5px; }
  .panel-filters .form-group .vamp-button {
    display: inline-block;
    width: auto;
    background: #383839;
    color: #FFF; }

.infos-panel {
  width: 100%; }

.downloads-list {
  list-style: none;
  padding: 0 0 0 0; }
  .downloads-list li {
    display: flex;
    align-items: center; }
  .downloads-list svg {
    max-width: 15px;
    height: auto;
    width: 100%;
    margin: 0 10px 0 0; }

.customer-invoices {
  width: 100%; }

.address-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  background: #383839;
  color: #FFF;
  padding: 10px; }
  @media (max-width: 600px) {
    .address-list-item {
      flex-direction: column;
      align-items: flex-start; }
      .address-list-item .address-label {
        margin: 0 0 15px 0; } }
  .address-list-item .address-controls {
    display: flex;
    align-items: center; }
  .address-list-item span.address-control {
    display: inline-block;
    cursor: pointer;
    margin: 0 0 0 15px; }
    @media (max-width: 600px) {
      .address-list-item span.address-control {
        margin: 0; } }
  .address-list-item svg {
    max-width: 20px;
    width: 100%;
    height: auto;
    fill: #FFF;
    margin: 0 0 0 10px; }

.form-controls {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.address-form-container {
  margin: 0 0 50px 0; }
  .address-form-container header {
    background: #FFF;
    color: #383839;
    border: 0;
    border-bottom: 1px solid #383839;
    padding: 15px 0; }

.address-list {
  list-style: none;
  padding: 0;
  margin: 0; }

.customer-addresses-panel {
  width: 100%; }

.order-details .panel-body .info-table th {
  text-align: center; }

.order-details .panel-body .info-table td {
  padding: 15px 30px !important;
  text-align: center; }

.order-details .panel-body .info-table img {
  max-width: 150px;
  width: 100%;
  height: auto; }

.customer-credits {
  width: 100%; }

.archive-header {
  width: 100%;
  box-sizing: border-box;
  margin: 0 0 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-end; }
  .archive-header .archive-header-delivery-title {
    color: green; }
  .archive-header .archive-header-center {
    margin-left: auto;
    margin-right: auto; }
  .archive-header h1 {
    text-align: center;
    color: #383839;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    font-size: 1.5rem; }
  .archive-header .pangination-info {
    font-size: 0.875rem; }
    .archive-header .pangination-info .active {
      font-weight: bold; }

.sample-page-controls {
  display: flex;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  background: #383839;
  left: 0;
  width: 100%;
  padding: 10px;
  box-sizing: border-box; }
  .sample-page-controls .label {
    font-weight: bold; }
  .sample-page-controls .value {
    font-weight: normal;
    margin: 0 0 0 10px; }
  .sample-page-controls .info-container {
    color: #FFF;
    display: flex;
    align-items: center; }
  .sample-page-controls .actions-container {
    display: flex;
    align-items: center; }
  .sample-page-controls .action-element {
    padding: 0 10px; }
    .sample-page-controls .action-element.view-only-selected.active svg {
      fill: #e84848; }
    .sample-page-controls .action-element svg {
      max-width: 30px;
      width: 100%;
      height: auto;
      fill: #FFF;
      cursor: pointer; }
  .sample-page-controls .checkout-action-element {
    background: #fff;
    color: #383839;
    padding: 10px;
    font-weight: bold; }
    .sample-page-controls .checkout-action-element svg {
      max-width: 25px;
      width: 100%;
      fill: #383839;
      margin: 0 0 0 6px; }

.samples-page .archive-container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px; }
  .samples-page .archive-container .product-item {
    box-sizing: border-box;
    width: 20%;
    padding: 0 15px;
    margin: 0 0 35px 0; }
    .samples-page .archive-container .product-item img {
      max-width: 100% !important;
      width: auto;
      margin: auto;
      max-height: 500px; }
    @media (max-width: 768px) {
      .samples-page .archive-container .product-item {
        width: 25%; } }
    @media (max-width: 600px) {
      .samples-page .archive-container .product-item {
        width: 33.33%; } }
    @media (max-width: 560px) {
      .samples-page .archive-container .product-item {
        width: 50%; } }

.colors-list-component ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex; }
  .colors-list-component ul .selectedColor {
    border: 1px solid #383839;
    padding-left: 8px;
    border-radius: 15px;
    padding-right: 8px;
    margin-left: 2px;
    margin-right: 2px; }
  .colors-list-component ul li {
    display: flex;
    align-items: center;
    padding: 0 5px 0 0;
    cursor: pointer;
    margin-left: 8px;
    margin-right: 8px; }
  .colors-list-component ul .color-hex {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin: 0 5px 0 0;
    display: inline-block;
    border: 1px solid rgba(217, 217, 217, 0.8); }

.family-archive-page header {
  font-size: 0.7rem;
  margin: 0 0 20px 0;
  color: #383839; }
  .family-archive-page header .title-container {
    text-align: center; }
    .family-archive-page header .title-container .title {
      font-size: 1.6rem; }
  .family-archive-page header .header-extras {
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .family-archive-page header .banner-header {
    margin: 40px 0; }
    .family-archive-page header .banner-header img {
      width: 100%; }
    .family-archive-page header .banner-header .banner-desktop {
      display: none; }
      @media (min-width: 960px) {
        .family-archive-page header .banner-header .banner-desktop {
          display: block; } }
    .family-archive-page header .banner-header .banner-mobile {
      display: none; }
      @media (max-width: 960px) {
        .family-archive-page header .banner-header .banner-mobile {
          display: block; } }
  .family-archive-page header ul {
    padding: 0;
    margin: 0;
    list-style: none; }
  .family-archive-page header .left-container {
    width: 33.33%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start; }
    @media (max-width: 768px) {
      .family-archive-page header .left-container {
        width: 50%;
        order: 2; } }
    @media (max-width: 560px) {
      .family-archive-page header .left-container {
        width: 100%; } }
    .family-archive-page header .left-container a {
      color: #919191;
      background: none;
      border: 0;
      cursor: pointer;
      text-decoration: none; }
    .family-archive-page header .left-container .back-to-season {
      margin: 0 0 10px 0;
      font-size: 1.1rem; }
    .family-archive-page header .left-container .colors-list {
      display: flex;
      flex-direction: column; }
      @media (max-width: 560px) {
        .family-archive-page header .left-container .colors-list {
          flex-direction: row;
          justify-content: flex-start;
          flex-wrap: wrap; } }
      .family-archive-page header .left-container .colors-list li {
        display: flex;
        align-items: center;
        margin: 0 0 5px 0; }
        @media (max-width: 560px) {
          .family-archive-page header .left-container .colors-list li {
            margin: 0 5px 0 0; } }
        .family-archive-page header .left-container .colors-list li:last-child {
          margin: 0; }
      .family-archive-page header .left-container .colors-list .color-hex {
        width: 13px;
        height: 13px;
        display: inline-block;
        border-radius: 50%;
        margin-right: 8px;
        border: 1px solid rgba(217, 217, 217, 0.8); }
  .family-archive-page header .right-container {
    width: 33.33%;
    display: flex;
    flex-direction: column;
    align-items: flex-end; }
    @media (max-width: 768px) {
      .family-archive-page header .right-container {
        width: 50%;
        order: 3; } }
    @media (max-width: 560px) {
      .family-archive-page header .right-container {
        width: 100%;
        align-items: flex-end; } }
    .family-archive-page header .right-container .family-composition {
      margin: 0 0 8px 0; }
    .family-archive-page header .right-container .sizes-list {
      display: flex; }
      .family-archive-page header .right-container .sizes-list li {
        padding: 0 4px; }
  .family-archive-page header .header-center {
    width: 50%;
    padding: 15px;
    justify-content: center;
    display: flex; }
    @media (max-width: 768px) {
      .family-archive-page header .header-center {
        width: 100%;
        order: 1;
        margin: 5px 0; } }
    .family-archive-page header .header-center .title {
      margin: 0; }

.sizes-list-component {
  display: flex;
  justify-content: center; }
  .sizes-list-component ul {
    padding: 0;
    margin: auto;
    display: flex;
    list-style: none;
    max-width: 320px;
    overflow-x: auto; }
    .sizes-list-component ul li {
      display: flex;
      align-items: center;
      padding: 0 5px 0 0; }

.product-item {
  position: relative; }

.wishlist-button {
  position: absolute;
  right: 5px;
  top: 5px; }
  .wishlist-button button {
    background: none;
    border: 0;
    cursor: pointer; }
    .wishlist-button button:focus {
      outline: none; }
  .wishlist-button svg {
    max-width: 35px;
    width: 100%;
    height: auto; }
    @media (max-width: 640px) {
      .wishlist-button svg {
        max-width: 30px; } }
  .wishlist-button.icon-heart svg {
    fill: #FFF;
    stroke-width: 10px;
    stroke: #000000; }
  .wishlist-button.icon-heart.is-in-wishlist svg {
    fill: #000 !important;
    stroke-width: 0; }

.product-item {
  text-align: center; }
  .product-item .product-in-sale-bubble {
    position: absolute;
    right: 0;
    top: 0;
    color: #e13c5d;
    padding: 5px; }
  .product-item .delivery-date {
    font-weight: bold; }
  .product-item > a {
    text-decoration: none; }
  .product-item .item-name {
    color: #7d7d7d; }
  .product-item .size-attributes {
    display: flex;
    justify-content: center; }
    @media (max-width: 560px) {
      .product-item .size-attributes {
        flex-direction: column; } }
    .product-item .size-attributes span {
      padding: 0 5px;
      border-right: 1px solid #383839; }
      @media (max-width: 560px) {
        .product-item .size-attributes span {
          border-right: 0;
          padding: 1px 0; } }
      .product-item .size-attributes span:last-child {
        border-right: 0; }
  .product-item .plus-size-icon {
    position: absolute;
    left: 15px;
    top: 15px; }
    .product-item .plus-size-icon svg {
      max-width: 60px;
      width: 100%;
      height: auto; }
      @media (max-width: 640px) {
        .product-item .plus-size-icon svg {
          max-width: 35px; } }
  .product-item .item-price {
    font-size: 1rem;
    font-weight: bold; }
    .product-item .item-price span {
      margin: 0 4px; }
    .product-item .item-price .regular-price {
      color: #919191;
      text-decoration: line-through; }
  .product-item img {
    width: 100%;
    height: auto; }
  .product-item .product-meta {
    padding: 15px 0;
    color: #383839;
    font-size: 1rem; }
    .product-item .product-meta > div {
      margin: 10px 0 0 0; }
    .product-item .product-meta .product-code {
      font-weight: bold; }
  .product-item .extra-meta {
    font-size: 0.875rem;
    text-align: center; }
    .product-item .extra-meta > div {
      margin: 0 0 10px 0; }
      .product-item .extra-meta > div:last-child {
        margin: 0; }
    .product-item .extra-meta ul {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      justify-content: center;
      overflow: auto; }
      .product-item .extra-meta ul li {
        padding: 0 5px; }
    .product-item .extra-meta .colors .color-name {
      display: none; }
    .product-item .extra-meta .colors .color-hex {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      display: inline-block;
      border: 1px solid rgba(217, 217, 217, 0.8); }
  .product-item .web-only-svg {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 90px; }
    @media (max-width: 900px) {
      .product-item .web-only-svg {
        width: 68px; } }
    @media (max-width: 680px) {
      .product-item .web-only-svg {
        width: 50px; } }
    @media (max-width: 560px) {
      .product-item .web-only-svg {
        width: 76px;
        left: 0px;
        top: 0px; } }
    @media (max-width: 480px) {
      .product-item .web-only-svg {
        width: 60px;
        left: 0px;
        top: 0px; } }

.product-item .delivery-date {
  position: absolute;
  left: 0px;
  top: 22px;
  padding: 5px;
  font-size: 0.8rem;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  border-top: 1px solid #383839;
  border-bottom: 1px solid #383839; }

.product-filters {
  font-size: 0.9rem;
  margin: 0 0 25px 0; }
  .product-filters .view-per-line {
    display: flex;
    align-items: center; }
    @media (max-width: 600px) {
      .product-filters .view-per-line {
        display: none !important; } }
    .product-filters .view-per-line ul {
      list-style: none;
      padding: 0;
      margin: 0 0 0 5px;
      display: flex; }
      .product-filters .view-per-line ul li {
        margin: 0 5px;
        padding: 5px;
        cursor: pointer; }
  .product-filters svg {
    width: 15px;
    height: auto; }
  .product-filters .products-found {
    display: flex;
    align-items: center; }
    @media (max-width: 600px) {
      .product-filters .products-found {
        display: none !important; } }
  .product-filters .product-search {
    max-width: 350px;
    margin: auto;
    position: relative; }
    .product-filters .product-search .search-icon {
      position: absolute;
      left: 5px;
      top: 9px;
      z-index: 2; }
    .product-filters .product-search .clear-icon {
      position: absolute;
      right: 5px;
      top: 5px;
      z-index: 2;
      cursor: pointer;
      padding: 5px; }
    .product-filters .product-search .search-icon, .product-filters .product-search .clear-icon {
      display: flex;
      align-items: center; }
    .product-filters .product-search input {
      display: block;
      padding: 8px 40px 8px 45px;
      border: 0;
      border-bottom: 1px solid #383839;
      box-sizing: border-box;
      font-size: 1rem;
      width: 100%; }

.site-global-bottom-bar .product-filters {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #FFF;
  margin: 0; }
  @media (max-width: 600px) {
    .site-global-bottom-bar .product-filters.has-bottom-navigation .product-search {
      display: none; } }
  .site-global-bottom-bar .product-filters .right-section {
    display: flex;
    box-sizing: border-box; }
    .site-global-bottom-bar .product-filters .right-section > div {
      margin: 0 0 0 15px; }

.bottom-navigation-arrows {
  display: flex;
  justify-content: center; }
  .bottom-navigation-arrows .next-navigation-item, .bottom-navigation-arrows .prev-navigation-item {
    margin: 0 15px;
    padding: 0 5px;
    cursor: pointer; }
    .bottom-navigation-arrows .next-navigation-item svg, .bottom-navigation-arrows .prev-navigation-item svg {
      max-width: 30px;
      width: 100%;
      fill: #FFF; }
  .bottom-navigation-arrows .prev-navigation-item svg {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }

.site-global-bottom-bar {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background: #383839;
  padding: 10px;
  box-sizing: border-box;
  z-index: 9; }
  @media (max-width: 768px) {
    .site-global-bottom-bar {
      font-size: 0.8rem; } }

.no-products-found-msg {
  text-align: center;
  padding: 35px 15px;
  margin: 0;
  font-size: 1.1rem;
  color: #383839; }

.products-list-top-section {
  padding: 15px; }
  .products-list-top-section .sort-by-section {
    display: flex;
    justify-content: flex-end; }
    .products-list-top-section .sort-by-section span {
      margin: 0 15px 0 0;
      font-size: 0.9rem; }

.products-list-component {
  text-align: center; }
  .products-list-component .product-list {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    list-style: none; }
    .products-list-component .product-list.items-per-line-2 > li {
      width: 50% !important; }
      @media (max-width: 560px) {
        .products-list-component .product-list.items-per-line-2 > li {
          width: 50% !important; } }
    .products-list-component .product-list.items-per-line-3 > li {
      width: 33.33% !important; }
      @media (max-width: 560px) {
        .products-list-component .product-list.items-per-line-3 > li {
          width: 50% !important; } }
    .products-list-component .product-list.items-per-line-4 > li {
      width: 25% !important; }
      @media (max-width: 560px) {
        .products-list-component .product-list.items-per-line-4 > li {
          width: 50% !important; } }
    .products-list-component .product-list > li {
      box-sizing: border-box;
      width: 33.33%;
      padding: 0 15px;
      margin: 0 0 35px 0;
      cursor: pointer; }
      .products-list-component .product-list > li article {
        transition: box-shadow 0.3s ease-in-out; }
        .products-list-component .product-list > li article:hover {
          box-shadow: 0 0 25px #a0a0a0a3; }
  .products-list-component .product-pagination-container {
    display: flex;
    align-items: center;
    justify-content: center; }
    .products-list-component .product-pagination-container svg {
      max-width: 18px;
      width: 100%;
      height: auto;
      margin: 0 5px; }
    .products-list-component .product-pagination-container .paginate-next-page,
    .products-list-component .product-pagination-container .paginate-prev-page {
      cursor: pointer; }
    .products-list-component .product-pagination-container .product-list-pagination {
      list-style: none;
      padding: 0;
      margin: 25px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center; }
      .products-list-component .product-pagination-container .product-list-pagination li {
        margin: 10px;
        padding: 13px;
        background: #FFF;
        color: #999;
        border: 1px solid #999;
        font-size: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 15px;
        height: 15px;
        cursor: pointer;
        border-radius: 50%; }
        .products-list-component .product-pagination-container .product-list-pagination li.active {
          background: #383839;
          color: #FFF; }

.family-products-container .prev-family, .family-products-container .next-family {
  position: fixed;
  cursor: pointer;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }
  .family-products-container .prev-family svg, .family-products-container .next-family svg {
    max-width: 150px;
    height: auto;
    fill: #383839; }

.family-products-container .prev-family {
  left: 40px; }

.family-products-container .next-family {
  right: 40px; }
  .family-products-container .next-family svg {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }

@media (min-width: 1400px) {
  .menu-bg {
    display: none;
    background: #FFF;
    position: absolute;
    display: none;
    width: 100%;
    height: 450px;
    left: 0;
    z-index: 9;
    box-shadow: 0 20px 18px #38383970; }
  .menu-wrapper:hover .menu-bg {
    display: none; }
  .archive-menu-navigator .mobile-archive-menu-toggle, .archive-menu-navigator .mobile-archive-menu-close {
    display: none; }
  .archive-menu-navigator .submenu a {
    margin: 0 0 10px 0;
    display: inline-block;
    padding: 2px;
    line-height: 1; }
  .archive-menu-navigator ul {
    list-style: none;
    padding: 0;
    margin: 0; }
  .archive-menu-navigator .link-wrapper a {
    transition: color 0.3s ease-in-out;
    color: #383839;
    font-size: 1.1rem;
    text-decoration: none; }
  .archive-menu-navigator .link-wrapper svg {
    display: none; }
  .archive-menu-navigator .menu-wrapper-container {
    display: flex;
    justify-content: center; }
  .archive-menu-navigator .menu-wrapper {
    display: inline-block;
    padding: 0 0 25px 0; }
    .archive-menu-navigator .menu-wrapper > ul {
      display: flex;
      justify-content: center;
      z-index: 10; }
      .archive-menu-navigator .menu-wrapper > ul > li {
        padding: 0 10px;
        margin: 0 15px;
        transition: background 0.3s ease-in-out; }
        .archive-menu-navigator .menu-wrapper > ul > li:hover {
          background: #383839; }
          .archive-menu-navigator .menu-wrapper > ul > li:hover > .link-wrapper a {
            color: #FFF; }
        .archive-menu-navigator .menu-wrapper > ul > li > .submenu {
          padding: 25px 0; }
          .archive-menu-navigator .menu-wrapper > ul > li > .submenu > li > .link-wrapper {
            border-bottom: 1px solid #383839; }
          .archive-menu-navigator .menu-wrapper > ul > li > .submenu .link-wrapper a {
            transition: color 0.3s ease-in-out; }
          .archive-menu-navigator .menu-wrapper > ul > li > .submenu .link-wrapper:hover a {
            color: #3f4c7a !important; }
        .archive-menu-navigator .menu-wrapper > ul > li.offers-menu:hover > .submenu > li > .submenu {
          display: flex;
          justify-content: flex-start; }
          .archive-menu-navigator .menu-wrapper > ul > li.offers-menu:hover > .submenu > li > .submenu > li {
            padding: 0 30px 0 0; }
            .archive-menu-navigator .menu-wrapper > ul > li.offers-menu:hover > .submenu > li > .submenu > li > .link-wrapper a {
              font-weight: bold; }
        .archive-menu-navigator .menu-wrapper > ul > li:hover > .submenu > li {
          padding: 15px 60px; }
          .archive-menu-navigator .menu-wrapper > ul > li:hover > .submenu > li > .link-wrapper a {
            font-weight: bold; }
          .archive-menu-navigator .menu-wrapper > ul > li:hover > .submenu > li .submenu a {
            font-size: 0.95rem; }
        .archive-menu-navigator .menu-wrapper > ul > li:hover .submenu {
          display: flex; }
          .archive-menu-navigator .menu-wrapper > ul > li:hover .submenu > li > .submenu {
            display: block; }
        .archive-menu-navigator .menu-wrapper > ul > li > .submenu {
          position: absolute;
          left: 0;
          width: 100vw;
          z-index: 999;
          background-color: white; }
    .archive-menu-navigator .menu-wrapper .submenu {
      display: none;
      justify-content: center; } }

@media (max-width: 1400px) {
  .archive-menu-navigator .mobile-archive-menu-toggle, .archive-menu-navigator .mobile-archive-menu-close {
    display: block;
    text-align: center;
    padding: 0 0 25px 0; }
    .archive-menu-navigator .mobile-archive-menu-toggle span, .archive-menu-navigator .mobile-archive-menu-close span {
      margin: auto;
      max-width: 100px; }
  .archive-menu-navigator .mobile-archive-menu-close {
    text-align: center;
    padding: 5px;
    cursor: pointer; }
    .archive-menu-navigator .mobile-archive-menu-close svg {
      max-width: 25px;
      width: 100%;
      height: auto; }
  .archive-menu-navigator .link-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .archive-menu-navigator .link-wrapper svg {
      max-width: 25px;
      height: auto;
      width: 100%;
      padding: 5px;
      cursor: pointer; }
  .archive-menu-navigator .menu-wrapper {
    background: #FFF;
    position: fixed;
    left: 0;
    top: 0;
    padding: 10px;
    z-index: 99;
    box-shadow: 0 0 10px #000;
    max-width: 200px;
    width: 100%;
    height: 100%;
    -webkit-transform: translateX(-130%);
            transform: translateX(-130%);
    transition: -webkit-transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out; }
    .archive-menu-navigator .menu-wrapper ul {
      list-style: none;
      padding: 0;
      margin: 0; }
      .archive-menu-navigator .menu-wrapper ul .submenu {
        display: none;
        font-size: 0.9rem; }
      .archive-menu-navigator .menu-wrapper ul .show-submenu > .link-wrapper svg {
        -webkit-transform: rotate(90deg);
                transform: rotate(90deg); }
      .archive-menu-navigator .menu-wrapper ul .show-submenu > .submenu {
        display: block; }
        .archive-menu-navigator .menu-wrapper ul .show-submenu > .submenu > li > .link-wrapper > a {
          padding-left: 25px; }
      .archive-menu-navigator .menu-wrapper ul .link-wrapper {
        border-bottom: 1px solid #d2d2d2; }
      .archive-menu-navigator .menu-wrapper ul a {
        display: block;
        padding: 15px 10px;
        color: #383839; }
  .archive-menu-navigator.show-mobile-categories-menu .menu-wrapper {
    -webkit-transform: translateX(0);
            transform: translateX(0); } }

.simone-logo-img {
  width: 240px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-top: 36px; }

.coming-soon-header-container {
  text-align: center;
  padding: 15px 0; }
  .coming-soon-header-container .coming-soon-header {
    margin: 0 0 15px 0; }
  .coming-soon-header-container p {
    background: #FFF;
    color: #383839;
    border: 1px solid #383839;
    padding: 5px 15px;
    font-size: 1.3rem;
    display: inline-block;
    margin: auto; }

.banner-desktop-dynamic-banner {
  display: none; }
  @media (min-width: 960px) {
    .banner-desktop-dynamic-banner {
      display: block; } }

.banner-mobile-dynamic-banner {
  display: none; }
  @media (max-width: 960px) {
    .banner-mobile-dynamic-banner {
      display: block; } }

.banner-header {
  margin: 40px 0; }
  .banner-header img {
    width: 100%; }
  .banner-header .banner-desktop {
    display: none; }
    @media (min-width: 960px) {
      .banner-header .banner-desktop {
        display: block; } }
  .banner-header .banner-mobile {
    display: none; }
    @media (max-width: 960px) {
      .banner-header .banner-mobile {
        display: block; } }

.wishlist-page header.page-header {
  margin: 0 0 35px 0;
  text-align: center; }

.wishlist-page .page-title {
  color: #383839;
  font-size: 2.1rem;
  margin: 0; }

.archive-title .page-title {
  text-align: center;
  padding: 0 0 25px 0;
  margin: 0; }
  .archive-title .page-title h1 {
    color: #383839;
    font-size: 1.6rem;
    margin: 0; }

.presentation-page .seasons-list {
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  list-style: none; }
  .presentation-page .seasons-list .seasons-item {
    color: #383839;
    text-align: center; }
  .presentation-page .seasons-list li {
    width: 100%;
    padding: 25px;
    box-sizing: border-box;
    cursor: pointer; }
    @media (max-width: 600px) {
      .presentation-page .seasons-list li {
        width: 100%; } }
    .presentation-page .seasons-list li div {
      width: 50%;
      display: inline-block; }
      @media (max-width: 768px) {
        .presentation-page .seasons-list li div {
          width: 100%; } }
    .presentation-page .seasons-list li h3 {
      margin: 15px 0;
      font-size: 1.3rem; }
      @media (max-width: 768px) {
        .presentation-page .seasons-list li h3 {
          font-size: 1.2rem; } }
    .presentation-page .seasons-list li img {
      width: 100%;
      height: auto; }

.customer-verification-page .error-messages span {
  display: block;
  font-size: 0.875rem;
  color: red; }

.customer-verification-page .customer-verification-form {
  max-width: 600px;
  margin: auto;
  text-align: center; }
  .customer-verification-page .customer-verification-form input {
    padding: 5px; }

.customer-verification-page .request-new-pin {
  margin: 25px 0;
  font-size: 0.875rem; }
  .customer-verification-page .request-new-pin button {
    margin: 10px 0 0 0;
    padding: 5px;
    border: 0;
    background: #0a0a0a;
    color: #FFF; }

video {
  max-width: 400px;
  height: auto; }

.product-price-container .discount-bubble {
  border: 1px solid #0a0a0a;
  padding: 3px;
  margin-left: 15px;
  font-size: 14px;
  line-height: 1;
  display: inline-block;
  font-weight: bold; }

.season-archive-header {
  margin: 0 0 25px 0; }
  .season-archive-header .title-container {
    text-align: center; }
  .season-archive-header .header-extras {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .season-archive-header .top-products-control {
    display: flex;
    justify-content: flex-end;
    margin: 15px 0; }
    .season-archive-header .top-products-control .action-button {
      font-size: 0.8rem; }
  .season-archive-header .page-title {
    color: #383839;
    font-size: 1.6rem;
    margin: 0; }
  .season-archive-header .right-container {
    display: flex;
    align-items: center;
    justify-content: center; }
  .season-archive-header .top-products-link {
    background: #383839;
    color: #FFF;
    padding: 5px 10px;
    margin-left: 15px; }
  @media (max-width: 600px) {
    .season-archive-header {
      flex-wrap: wrap; }
      .season-archive-header .page-title {
        width: 100%;
        order: 1;
        text-align: center;
        margin: 0 0 15px 0; }
      .season-archive-header .left-container {
        order: 2;
        width: 50%;
        text-align: left; }
      .season-archive-header .right-container {
        order: 3;
        width: 50%;
        text-align: right;
        display: flex;
        justify-content: flex-end; } }

.family-filters {
  margin: 25px 0; }
  .family-filters .family-filter-element {
    display: flex;
    align-items: center;
    position: relative; }
    .family-filters .family-filter-element svg {
      max-width: 20px;
      height: auto;
      width: 100%;
      margin-right: 5px; }
  .family-filters .web-only-label {
    position: absolute;
    top: 28px;
    width: 92px;
    text-align: center;
    color: #d47eac;
    font-size: 12px; }
  .family-filters .vamp-button {
    display: none; }
  @media (max-width: 768px) {
    .family-filters .vamp-button {
      max-width: 150px;
      display: block; } }
  .family-filters ul {
    padding: 0;
    margin-bottom: 20px;
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center; }
    .family-filters ul li {
      color: #383839;
      font-size: 1rem;
      padding: 10px 15px;
      cursor: pointer; }
      @media (max-width: 992px) {
        .family-filters ul li {
          padding: 10px;
          font-size: 0.875rem; } }
      .family-filters ul li.active {
        color: #000;
        font-weight: 700;
        border: 1px solid #000; }
        .family-filters ul li.active svg {
          fill: #FFF; }
    @media (max-width: 768px) {
      .family-filters ul {
        display: none;
        background: #383839; }
        .family-filters ul.show-filters {
          display: block; }
        .family-filters ul li {
          color: #FFF;
          border: 1px solid transparent; }
          .family-filters ul li.active {
            background: #FFF;
            color: #383839;
            border: 1px solid #383839; } }

.family-item {
  padding: 15px;
  color: #383839;
  text-align: center;
  cursor: pointer;
  position: relative; }
  .family-item .web-only-svg {
    position: absolute;
    top: 19px;
    left: 25px;
    width: 90px; }
    @media (max-width: 600px) {
      .family-item .web-only-svg {
        width: 79px;
        left: 16px;
        top: 12px; } }
    @media (max-width: 460px) {
      .family-item .web-only-svg {
        width: 64px;
        left: 14px;
        top: 10px; } }
  .family-item img {
    width: 100%; }
  .family-item .family-friendly-name {
    padding: 14px 0; }

.families-list {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  list-style: none; }
  .families-list > li {
    width: 33.33%;
    margin: 0 0 25px 0; }
    @media (max-width: 768px) {
      .families-list > li {
        width: 50%; } }

.not-found-msg {
  text-align: center; }

.campaign-page {
  display: flex;
  flex-wrap: wrap; }
  .campaign-page a {
    display: inline-block;
    width: 100%; }
    .campaign-page a.video {
      width: 100%; }
      .campaign-page a.video video {
        max-width: 100%; }
    .campaign-page a img, .campaign-page a video {
      width: 100%; }
    @media (max-width: 768px) {
      .campaign-page a {
        width: 100%; } }

.admin-section {
  margin: 0 0 0 0; }
  .admin-section header {
    background: #383839;
    color: #FFF;
    padding: 15px;
    margin: 0 0 15px 0; }
  .admin-section .admin-section-content .form-element {
    display: flex;
    width: 100%; }
    .admin-section .admin-section-content .form-element input {
      max-width: 100px; }

.admin-header-clickable {
  cursor: pointer; }

.admin-page .admin-menu-navigator {
  border-bottom: 1px solid #383839;
  padding: 15px 0; }
  .admin-page .admin-menu-navigator a {
    background: #383839;
    color: #FFF;
    padding: 5px;
    margin: 0 5px 0 0;
    border: 1px solid #383839;
    text-transform: none;
    text-decoration: none;
    transition: background 0.3s ease-in-out, color 0.3s ease-in-out; }
    .admin-page .admin-menu-navigator a:hover {
      background: #FFF;
      color: #383839; }

.admin-page .user-password-input .td-container {
  display: flex; }

.admin-page .user-password-input {
  display: flex; }

.admin-page .basic-multi-select, .admin-page .basic-select {
  width: 100%; }

.admin-page .form-element button {
  background: #383839;
  color: #FFF;
  border: 0;
  padding: 12px 25px;
  max-width: 150px;
  width: 100%;
  cursor: pointer; }

.admin-page .form-element input[type='text'], .admin-page .form-element input[type='email'] {
  width: 100%;
  max-width: 100%; }

.admin-page .users-list {
  width: 100%;
  font-size: 0.875rem; }
  @media (max-width: 768px) {
    .admin-page .users-list {
      overflow-x: auto;
      display: block; } }
  .admin-page .users-list th {
    background: #383839;
    color: #FFF; }
  .admin-page .users-list td, .admin-page .users-list th {
    padding: 5px; }
  .admin-page .users-list input {
    padding: 6px; }

.admin-section-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.4s ease-out; }

.application-header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #383839;
  padding-bottom: 25px;
  margin-bottom: 25px; }
  .application-header .sample-cart {
    left: 40px !important; }
  .application-header .new-product-label {
    left: 58px !important;
    background-color: green !important;
    width: 34px !important;
    font-size: 9px !important;
    border-radius: 16px !important;
    height: 17px !important; }
  .application-header .header-item {
    color: #383839;
    font-size: .813rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px 25px;
    text-align: center;
    border-right: 1px solid #d9d9d9;
    cursor: pointer;
    box-sizing: border-box; }
    @media (max-width: 1400px) {
      .application-header .header-item {
        padding: 15px 20px; } }
    @media (max-width: 768px) {
      .application-header .header-item {
        padding: 0 15px;
        border: 0; } }
    .application-header .header-item.cart-icon {
      position: relative; }
      .application-header .header-item.cart-icon .counter {
        position: absolute;
        left: 0;
        top: 0;
        background: #000;
        color: #FFF;
        padding: 4px;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        font-size: 0.8rem;
        display: flex;
        align-items: center;
        justify-content: center; }
  .application-header .left-header-container {
    display: flex; }
    .application-header .left-header-container .secondary-banner {
      margin-left: 20px; }
      .application-header .left-header-container .secondary-banner img {
        width: 100%; }
      @media (max-width: 400px) {
        .application-header .left-header-container .secondary-banner {
          display: none; } }
    .application-header .left-header-container svg {
      max-width: 140px;
      height: auto;
      cursor: pointer; }
      @media (max-width: 768px) {
        .application-header .left-header-container svg {
          max-width: 100px; } }
  .application-header .right-header-container .right-header-inner {
    display: flex;
    align-items: center;
    justify-content: center; }
  .application-header .right-header-container .header-burger-menu {
    box-sizing: border-box;
    max-width: 200px;
    width: 100%;
    box-shadow: 0 0 10px #000;
    background: #FFF;
    padding: 15px;
    position: fixed;
    right: 0;
    top: 0;
    height: 100%;
    overflow-y: auto;
    -webkit-transform: translateX(110%);
            transform: translateX(110%);
    transition: -webkit-transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
    z-index: 99;
    color: #000;
    border: 0; }
    .application-header .right-header-container .header-burger-menu .header-item {
      border: 0;
      margin: 0 0 15px 0;
      padding: 5px;
      width: 100%; }
    .application-header .right-header-container .header-burger-menu.burger-menu-open {
      -webkit-transform: translateX(0);
              transform: translateX(0); }
  .application-header .right-header-container svg {
    max-width: 25px;
    height: auto;
    width: 100%;
    margin-bottom: 5px; }
  .application-header .right-header-container .header-customer-container a {
    text-decoration: none;
    color: #383839;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
  .application-header .header-customer-logout {
    padding: 0 30px;
    text-align: center; }
  @media (max-width: 768px) {
    .application-header .header-customer-logout {
      width: 100%;
      padding: 0; }
      .application-header .header-customer-logout .vamp-button {
        margin: auto; } }

.simone-perele-link-header {
  position: absolute;
  top: 132px;
  left: 0px; }
  @media (max-width: 630px) {
    .simone-perele-link-header {
      top: 125px; } }
  @media (max-width: 400px) {
    .simone-perele-link-header {
      top: 99px; } }

.simone-logo-img-header {
  width: 210px;
  display: block;
  cursor: pointer; }
  @media (max-width: 630px) {
    .simone-logo-img-header {
      width: 115px; } }

.product-loader {
  text-align: center; }

.product-single-page .product-single-header {
  margin: 0 0 25px 0; }

.product-single-page .single-product-add-to-cart {
  font-weight: bold; }

.product-single-page .product-images {
  margin: 0 auto 35px auto;
  max-width: 1400px;
  width: 100%; }

.product-single-page .product-price {
  font-weight: bold;
  font-size: 1.1rem; }

.product-single-page .product-bottom {
  max-width: 800px;
  margin: auto;
  display: flex;
  justify-content: space-between; }
  .product-single-page .product-bottom .product-meta {
    color: #383839;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 0.9rem; }
    .product-single-page .product-bottom .product-meta > div {
      margin: 0 0 10px 0; }
    .product-single-page .product-bottom .product-meta .product-sizes .sizes-list-component {
      justify-content: flex-start; }
    .product-single-page .product-bottom .product-meta .product-code {
      font-size: 1.2rem;
      font-weight: bold; }
    .product-single-page .product-bottom .product-meta .single-product-add-to-cart {
      font-size: 1.2rem;
      max-width: 250px;
      width: 100%;
      display: block;
      background: #383839;
      color: #FFF; }
  .product-single-page .product-bottom .product-controls {
    max-width: 250px;
    width: 100%; }
    .product-single-page .product-bottom .product-controls svg {
      max-width: 22px;
      width: 100%;
      display: block; }
    .product-single-page .product-bottom .product-controls .vamp-button {
      margin: 0 0 15px 0;
      padding: 12px;
      font-size: 1.1rem; }

.product-single-page .site-global-bottom-bar {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  @media (max-width: 768px) {
    .product-single-page .site-global-bottom-bar .product-code, .product-single-page .site-global-bottom-bar .product-price {
      display: none !important; } }
  @media (max-width: 640px) {
    .product-single-page .site-global-bottom-bar {
      flex-direction: column; }
      .product-single-page .site-global-bottom-bar .product-name {
        border-right: 0 !important;
        margin: 0 0 5px 0;
        text-align: center; } }
  .product-single-page .site-global-bottom-bar .product-info {
    color: #FFF;
    display: flex;
    align-items: center; }
    .product-single-page .site-global-bottom-bar .product-info > div {
      padding: 0 8px;
      border-right: 2px solid #FFFFFF; }
      .product-single-page .site-global-bottom-bar .product-info > div:last-child {
        border-right: 0; }
  .product-single-page .site-global-bottom-bar .product-controls {
    display: flex;
    align-items: center;
    color: #FFF; }
    .product-single-page .site-global-bottom-bar .product-controls svg {
      max-width: 35px;
      width: 100%;
      height: auto; }
    .product-single-page .site-global-bottom-bar .product-controls > div {
      margin: 0 0 0 20px; }
    .product-single-page .site-global-bottom-bar .product-controls .wishlist-button {
      position: static; }
      .product-single-page .site-global-bottom-bar .product-controls .wishlist-button.is-in-wishlist svg {
        fill: #FFF !important; }
      .product-single-page .site-global-bottom-bar .product-controls .wishlist-button svg {
        fill: transparent;
        stroke: #FFFFFF; }
    .product-single-page .site-global-bottom-bar .product-controls .product-quickview-button {
      cursor: pointer; }
      .product-single-page .site-global-bottom-bar .product-controls .product-quickview-button svg {
        fill: #FFF; }

.fancybox-video {
  width: 100%;
  max-width: 100%; }

.product-images-container {
  padding: 0 45px; }
  .product-images-container .owl-nav .owl-prev, .product-images-container .owl-nav .owl-next {
    margin: 10px !important; }
    .product-images-container .owl-nav .owl-prev:focus, .product-images-container .owl-nav .owl-next:focus {
      outline: none !important; }
    .product-images-container .owl-nav .owl-prev:hover, .product-images-container .owl-nav .owl-next:hover {
      background: transparent !important;
      color: #383839 !important;
      outline: none !important; }
      .product-images-container .owl-nav .owl-prev:hover button, .product-images-container .owl-nav .owl-next:hover button {
        outline: none !important; }
    .product-images-container .owl-nav .owl-prev span, .product-images-container .owl-nav .owl-next span {
      font-size: 4rem; }
      .product-images-container .owl-nav .owl-prev span:focus, .product-images-container .owl-nav .owl-next span:focus {
        outline: none !important; }
  .product-images-container .owl-nav .owl-prev {
    position: absolute;
    left: -45px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%); }
  .product-images-container .owl-nav .owl-next {
    position: absolute;
    right: -45px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%); }
  .product-images-container .owl-dots button {
    outline: none; }
  .product-images-container .owl-dots span {
    width: 10px !important;
    height: 10px !important; }
  .product-images-container .owl-dots .owl-dot.active span {
    background: #383839 !important; }
  .product-images-container .carousel-item img, .product-images-container .carousel-item video {
    max-width: 800px;
    height: auto;
    width: 100%; }
  .product-images-container video:focus, .product-images-container img:focus {
    outline: none;
    border: 0; }

.product-link {
  font-size: 22px; }

.product-grid-mode .product-attributes {
  font-size: 0.875rem;
  margin: 5px 0; }
  .product-grid-mode .product-attributes > span {
    margin: 0 5px 0 0; }
    .product-grid-mode .product-attributes > span:after {
      content: '|';
      padding: 0 0 0 5px; }
    .product-grid-mode .product-attributes > span:last-child:after {
      content: none; }

.product-grid-mode .grid-container {
  display: flex; }
  @media (max-width: 992px) {
    .product-grid-mode .grid-container {
      display: block; } }
  .product-grid-mode .grid-container > .left-container {
    width: 20%;
    padding: 0 15px 0 0; }
    @media (max-width: 992px) {
      .product-grid-mode .grid-container > .left-container {
        width: 100%; }
        .product-grid-mode .grid-container > .left-container .product-images {
          max-width: 400px;
          width: 100%;
          margin: auto; } }
  .product-grid-mode .grid-container > .right-container {
    width: 80%; }
    @media (max-width: 992px) {
      .product-grid-mode .grid-container > .right-container {
        width: 100%; } }
  .product-grid-mode .grid-container .product-header {
    margin: 0 0 15px 0;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    @media (max-width: 992px) {
      .product-grid-mode .grid-container .product-header {
        display: block; } }
    .product-grid-mode .grid-container .product-header .product-availability {
      margin: 5px 0; }
      .product-grid-mode .grid-container .product-header .product-availability.unavailable {
        color: red; }
      .product-grid-mode .grid-container .product-header .product-availability.available {
        color: green; }
      .product-grid-mode .grid-container .product-header .product-availability.first-tier {
        color: yellow; }
      .product-grid-mode .grid-container .product-header .product-availability.second-tier {
        color: brown; }
    .product-grid-mode .grid-container .product-header .title-container {
      display: flex;
      align-items: center;
      margin: 0 0 5px 0;
      font-size: 22px; }
      @media (max-width: 992px) {
        .product-grid-mode .grid-container .product-header .title-container .title {
          margin: 0 0 5px 0; } }
      @media (max-width: 992px) {
        .product-grid-mode .grid-container .product-header .title-container {
          display: block; } }
      .product-grid-mode .grid-container .product-header .title-container .product-fabric {
        margin: 0 0 0 10px; }
        @media (max-width: 992px) {
          .product-grid-mode .grid-container .product-header .title-container .product-fabric {
            margin: 0 0 5px 0; } }
        .product-grid-mode .grid-container .product-header .title-container .product-fabric:before {
          padding: 0 10px 0 0;
          content: '|'; }
          @media (max-width: 992px) {
            .product-grid-mode .grid-container .product-header .title-container .product-fabric:before {
              content: none;
              padding: 0; } }
  @media (max-width: 768px) {
    .product-grid-mode .grid-container .multi-grid-wrapper .multigrid-table {
      width: 100%;
      display: block;
      overflow: auto; } }

.product-price-container {
  font-size: 30px;
  font-weight: bold; }

.simone-logo-container-product-page > img {
  width: 210px; }

.simone-logo-preorder-product {
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-bottom: 35px; }

.admin-page-menu-header {
  text-align: center;
  margin-top: 30px; }

.admin-menu-editor .menu-editor {
  display: flex; }
  .admin-menu-editor .menu-editor .rst__collapseButton {
    display: none; }
  .admin-menu-editor .menu-editor .rst__rowTitleWithSubtitle, .admin-menu-editor .menu-editor .rst__rowSubtitle {
    height: 25px; }
  .admin-menu-editor .menu-editor .rst__tree {
    width: 80%;
    height: 100vh; }
  .admin-menu-editor .menu-editor .menu-actions {
    width: 20%;
    padding-left: 25px; }
  .admin-menu-editor .menu-editor .add-node-container .form-group {
    font-size: 14px; }
    .admin-menu-editor .menu-editor .add-node-container .form-group label {
      display: block; }
    .admin-menu-editor .menu-editor .add-node-container .form-group input {
      width: 100%;
      padding: 5px; }

.notices-presenter {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #383839;
  color: #FFF;
  padding: 10px;
  text-align: center;
  z-index: 999999; }
  .notices-presenter ul {
    list-style: none;
    margin: 0;
    padding: 0; }

.cart-mutation-popup {
  position: fixed;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 999999;
  background: #FFF;
  box-shadow: 0 0 10px #383839; }
  .cart-mutation-popup .message-text {
    text-align: center; }
    .cart-mutation-popup .message-text p {
      text-align: center; }
  .cart-mutation-popup .outer-wrap {
    position: relative;
    padding: 25px; }
  .cart-mutation-popup .inner-container {
    padding: 10px; }
  .cart-mutation-popup .close-popup {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer; }
    .cart-mutation-popup .close-popup svg {
      max-width: 18px;
      width: 100%;
      height: auto; }
  .cart-mutation-popup .controls {
    display: flex;
    align-items: center;
    justify-content: center; }
    .cart-mutation-popup .controls button {
      background: #383839;
      color: #FFF;
      border: 0;
      padding: 10px;
      margin: 10px; }

#scroll-to-top-component {
  position: fixed;
  right: 25px;
  bottom: 85px;
  z-index: 9999;
  cursor: pointer;
  display: none; }
  #scroll-to-top-component.show-element {
    display: block; }
  #scroll-to-top-component .scroll-to-top-container {
    border-radius: 50%;
    background: #383839;
    padding: 10px;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center; }
  #scroll-to-top-component svg {
    fill: #FFF;
    max-width: 100%;
    height: auto;
    width: 100%; }

.customer-accept-access-page {
  text-align: center;
  max-width: 400px;
  margin: auto; }
  .customer-accept-access-page .grant-access-form-container .extra-notice {
    font-size: 0.875rem; }
  .customer-accept-access-page .grant-access-form-container .form-errors {
    color: red;
    margin: 10px 0; }
  .customer-accept-access-page .grant-access-form-container .form-input {
    margin: 0 0 15px 0; }
    .customer-accept-access-page .grant-access-form-container .form-input label {
      margin: 0 0 5px 0;
      display: block;
      width: 100%;
      text-align: left;
      font-size: 0.9rem; }
    .customer-accept-access-page .grant-access-form-container .form-input input {
      display: block;
      width: 100%;
      box-sizing: border-box;
      padding: 8px; }
    .customer-accept-access-page .grant-access-form-container .form-input button {
      background: #000;
      color: #FFF;
      border: 0;
      padding: 8px 15px; }

.back-button {
  color: #919191;
  font-size: 1.2rem;
  background: none;
  border: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-decoration: none; }
  .back-button svg {
    margin-right: 5px;
    fill: #919191;
    max-width: 25px;
    width: 100%;
    height: auto; }

.action-button {
  color: #919191;
  font-size: 1rem;
  background: none;
  border: 0;
  cursor: pointer;
  display: flex;
  align-items: center; }
  .action-button svg {
    margin-right: 5px;
    fill: #919191;
    max-width: 25px;
    width: 100%;
    height: auto; }
  .action-button span {
    line-height: 1; }
  .action-button:focus {
    outline: none; }

.vamp-button {
  box-sizing: border-box;
  background: #fff;
  color: #383839;
  border: 1px solid #383839;
  max-width: 250px;
  text-align: center;
  padding: 8px 15px;
  cursor: pointer;
  transition: background 0.3s ease-in-out, color 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9rem; }
  .vamp-button.vamp-red {
    background: red;
    color: #FFF; }
  .vamp-button.vamp-green {
    background: green;
    color: #FFF; }
  .vamp-button svg {
    max-width: 20px;
    width: 100%;
    height: auto;
    margin-left: 20px; }
  .vamp-button:hover {
    background: #383839;
    color: #fff; }
  .vamp-button.vamp-button-blue {
    background: #383839;
    color: #FFF;
    border: 1px solid #383839; }
    .vamp-button.vamp-button-blue:hover {
      background: #FFF;
      color: #383839; }

.language-footer {
  height: 10px;
  position: fixed;
  bottom: 15px;
  right: 10px; }
  .language-footer span {
    cursor: pointer; }

.selected-language {
  color: #3f4c7a;
  text-decoration: underline; }

.not-found-entry-page-message {
  text-align: center; }
  .not-found-entry-page-message .logo-container {
    margin: 35px 0; }
  .not-found-entry-page-message h2 {
    font-size: 1.1rem; }
  .not-found-entry-page-message .login-link a {
    color: #FFF;
    background: #383839;
    margin: 25px 0;
    max-width: 150px;
    width: 100%;
    padding: 5px 10px;
    display: inline-block; }

.prices {
  display: inline-block; }
  .prices .regular-price {
    text-decoration: line-through;
    color: #919191; }
  .prices .price {
    color: #0a0a0a;
    font-weight: bold;
    padding: 0 0 0 5px; }

.fancybox-navigation .fancybox-button {
  height: 130px;
  width: 100px; }
  @media (max-width: 560px) {
    .fancybox-navigation .fancybox-button {
      height: 100px;
      width: 80px; } }

.carousel-item {
  display: block !important; }

body, html {
  margin: 0;
  padding: 0; }

body {
  font-size: 16px;
  font-family: 'Noto Sans', sans-serif; }

div {
  box-sizing: border-box; }

input:focus, select:focus, textarea:focus {
  outline: none; }

.application-container {
  max-width: 1400px;
  padding: 15px;
  margin: 0 auto 100px auto;
  position: static; }

.loading-container {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

.pagination-component {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  list-style: none;
  margin: 25px 0;
  padding: 0; }
  .pagination-component .pagination-number {
    padding: 12px;
    border: 1px solid #d9d9d9;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 50px;
    cursor: pointer;
    transition: background .3s ease-in-out,color .3s ease-in-out; }
    .pagination-component .pagination-number.active {
      background: #383839;
      color: #FFF; }

.orders-table {
  width: 100%;
  border-spacing: 0;
  font-size: 0.875rem; }
  @media (max-width: 768px) {
    .orders-table {
      display: block;
      overflow-x: auto; } }
  .orders-table th {
    padding: 10px;
    background: #383839;
    color: #fff; }
  .orders-table td {
    border-bottom: 1px solid #d9d9d9;
    padding: 5px;
    text-align: center; }
    .orders-table td .action-area {
      cursor: pointer; }
    .orders-table td a {
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center; }
      .orders-table td a svg {
        margin-right: 5px; }
    .orders-table td svg {
      max-width: 30px;
      height: auto;
      width: 100%;
      fill: #383839; }

.form-submit-button {
  background: #383839;
  color: #fff;
  font-size: 18px;
  padding: 10px;
  cursor: pointer;
  border: 0; }

.banner-temp-image {
  text-align: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 320px; }

